// import Deconnexion from "components/Deconnexion";
// import HacButton from "components/HacButton";
import HacButton from "components/HacButton";
import HacFlex from "components/HacFlex";
import HacText from "components/HacText";
import Loader from "components/Loader";
import { bgColorPrimary } from "constants";
import useGetUserInfo from "hooks/useGetUserInfo";
import { Template } from "layouts/Template";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetCoachQuery } from "redux/features/coachApi";
import { useAddSessionMutation } from "redux/features/sessionApi";
import { addCurrentDiagnostique } from "redux/features/userSlice";

export default function Diagnostique() {
  const navigation = useNavigate();

  const dispatch = useDispatch();

  const { userId, csrfToken, userStatus } = useGetUserInfo();
  const { isLoading } = useGetCoachQuery(userId);
  const [addSession] = useAddSessionMutation();
  // console.log(data, error, isError);

  return (
    <Template
      mainStyle={{
        backgroundImage: "url('./diagnostique-bg.png')",
        backgroundSize: "cover",
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: ".6rem",
        position: "relative",
      }}
    >
      <div className="logo" style={{ marginTop: 30 }}>
        <img style={{ width: 70 }} src="../../icons/icon1.png" alt="" />
      </div>
      <div className="image" style={{ marginTop: -40 }}>
        <img
          style={{ width: "100%" }}
          src="../../diagnostique-acceuil.png"
          alt=""
        />
      </div>
      <div
        className="info"
        style={{
          height: "auto",
          backgroundColor: "#fff",
          width: "100%",
          borderRadius: 10,
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 20,
          fontWeight: 600,
          lineHeight: 1.5,
        }}
      >
        {isLoading ? (
          <Loader type={true} />
        ) : userStatus !== "PARENT" ? (
          <>
            <HacText ta={"center"} size={15} weight={600}>
              Avant de poursuivre, veuillez éffectuer un diagnostique pour
              évaluer le niveau des élèves.
            </HacText>
            <HacFlex
              wrap="nowrap"
              position={"apart"}
              w={"90%"}
              gap={20}
              my={20}
            >
              <div
                className=""
                style={{
                  // width: "50%",
                  flex: 1,

                  height: 100,
                  borderRadius: 10,
                  backgroundColor: " #5EA9FA",
                  display: "grid",
                  placeItems: "center",
                }}
                onClick={async () => {
                  if (userStatus === "ADMIN") {
                    dispatch(addCurrentDiagnostique("ADMIN"));
                    navigation("coach");
                  } else {
                    dispatch(addCurrentDiagnostique("COACH"));
                    navigation("ecoles");
                  }
                  try {
                    await addSession({
                      token: csrfToken,
                      body: { userId: userId },
                    });
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                <div className="">
                  <HacFlex position={"center"}>
                    <img src="../../icons/school.png" alt="" width={40} />
                  </HacFlex>
                  <HacText
                    ta={"center"}
                    weight={700}
                    size={15}
                    mt={10}
                    color={"#fff"}
                  >
                    {userStatus === "ADMIN" ? "Coachs" : "Ecoles"}
                  </HacText>
                </div>
              </div>
              <div
                className=""
                style={{
                  // width: "50%",
                  flex: 1,
                  height: 100,
                  borderRadius: 10,
                  backgroundColor: "#2A8899",
                  display: "grid",
                  placeItems: "center",
                }}
                onClick={() => {
                  dispatch(addCurrentDiagnostique("PARENT"));
                  navigation("parent/list");
                }}
              >
                <div className="">
                  <HacFlex position={"center"}>
                    <img src="../../icons/parent.png" alt="" width={40} />
                  </HacFlex>
                  <HacText
                    ta={"center"}
                    weight={700}
                    size={15}
                    mt={10}
                    color={"#fff"}
                  >
                    Parents
                  </HacText>
                </div>
              </div>
            </HacFlex>
          </>
        ) : userStatus === "PARENT" ? (
          <>
            <HacText ta={"center"} size={15} weight={600}>
              Cher parent veuillez effectuer un diagnostic pour évaluer le
              niveau de vos élèves."
            </HacText>
            <HacButton
              w={"100%"}
              radius={20}
              bg={bgColorPrimary}
              onClick={() => {
                dispatch(addCurrentDiagnostique("PARENT"));
                navigation("parent/list/eleves/" + userId);
              }}
            >
              Commencer
            </HacButton>
          </>
        ) : null}
      </div>
    </Template>
  );
}
