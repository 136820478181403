import Empty from "components/Empty";
import HacFlex from "components/HacFlex";
import HacInputText from "components/HacInputText";
import HacText from "components/HacText";
import Loader from "components/Loader";
import { Template } from "layouts/Template";
import HeaderDiagnostique from "pages/testDePositionnement/HeaderDiagnostique";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetElevesByParentQuery } from "redux/features/eleveApi";
import { useGetNiveauxQuery } from "redux/features/niveauApi";
import { addClasseId, setElevePositionnement } from "redux/features/userSlice";

function ParentEleveList() {
  const { parentId } = useParams();

  const navigation = useNavigate();
  const location = useLocation();

  const [query, setQuery] = useState("");

  const { data, isLoading } = useGetElevesByParentQuery({
    parentId: parseInt(parentId),
  });

  const { data: niveaux } = useGetNiveauxQuery();

  const dispatch = useDispatch();

  // console.log({ eleve: data });
  return (
    <Template
      mainStyle={{ backgroundColor: "rgba(0,0,0,0.1)" }}
      topBar={
        <HeaderDiagnostique
          title={
            <>
              <HacText weight={700} size={15}>
                Liste des eleves de{" "}
              </HacText>
              {location?.state && (
                <HacText weight={400} size={13}>
                  {location?.state.toLowerCase()}
                </HacText>
              )}
            </>
          }
        />
      }
    >
      <div
        style={{
          padding: ".5rem",
          paddingTop: 20,
        }}
      >
        <div style={{ position: "sticky", top: 10 }}>
          <HacInputText
            placeholder={"Rechercher un eleve"}
            bg={"#fff"}
            h={20}
            radius={10}
            mb={20}
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
            value={query}
          />
        </div>

        {isLoading ? (
          <Loader type={true} />
        ) : data?.data.length > 0 ? (
          [...data?.data]
            .filter(
              (eleve) =>
                eleve?.firstName.toLowerCase().includes(query) ||
                eleve?.lastName.toLowerCase().includes(query)
            )
            .map((item) => (
              <div
                className=""
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  padding: ".7rem",
                  borderRadius: 10,
                  marginBottom: 10,
                }}
                key={item?.id}
                onClick={() => {
                  dispatch(setElevePositionnement(item));
                  dispatch(addClasseId(item?.classeId));
                  navigation("parentEleve", { state: { parentId } });
                }}
              >
                <HacFlex gap={15}>
                  <div
                    className=""
                    style={{
                      backgroundColor: "rgba(0,0,0,0.1)",
                      width: 50,
                      height: 50,
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "50%",
                    }}
                  >
                    <img src="../../../icons/faceBlack.png" alt="" width={25} />
                  </div>
                  <div className="">
                    <HacText weight={700} size={15} color={"#003880"}>
                      {item?.firstName} {item?.lastName}
                    </HacText>
                    <HacText mt={3} size={12}>
                      Ecole: {item?.school?.name}
                    </HacText>
                    <HacText mt={3} size={12}>
                      Classe: {item?.classe?.name}
                    </HacText>
                    <HacFlex gap={10} mt={10}>
                      {item?.niveaux.map((niveau, index) => (
                        <div
                          key={niveau}
                          style={
                            {
                              // backgroundColor: "rgba(0,0,0,0.07)",
                              // padding: "3px 10px",
                              // borderRadius: 20,
                              // border: "1px solid rgba(0,0,0,0.1)",
                            }
                          }
                        >
                          <HacText size={10} color={"gray"}>
                            {
                              niveaux?.data.find((niv) => niv.id === niveau)
                                ?.name
                            }{" "}
                            {index + 1 === item?.niveaux.length ? null : "/"}
                          </HacText>
                        </div>
                      ))}
                    </HacFlex>
                  </div>
                </HacFlex>
              </div>
            ))
        ) : (
          <Empty />
        )}

        <div className="" style={{ height: 10 }}></div>
      </div>
    </Template>
  );
}

export default ParentEleveList;
