import HacFlex from "components/HacFlex";
import HacInputText from "components/HacInputText";
import HacText from "components/HacText";
import Loader from "components/Loader";
import useGetUserInfo from "hooks/useGetUserInfo";
import { Template } from "layouts/Template";
import HeaderDiagnostique from "pages/testDePositionnement/HeaderDiagnostique";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetDiagnostiqueTentativeByCoachQuery } from "redux/features/diagnostiqueApi";
import { User } from "tabler-icons-react";

function HistoriqueListeDiagnostique() {
  const { userId } = useGetUserInfo();

  const navigation = useNavigate();

  const [query, setQuery] = useState("");

  const { data: diagnostiqueData, isLoading: diagnosticLoading } =
    useGetDiagnostiqueTentativeByCoachQuery(userId);
  return (
    <Template
      topBar={<HeaderDiagnostique title={"Liste des eleves diagnostiquer"} />}
      mainStyle={{ backgroundColor: "rgba(0,0,0,0.1)", padding: ".5rem" }}
    >
      {/* lalalal */}
      <div className="" style={{ position: "sticky", top: 10 }}>
        <HacInputText
          placeholder={"Rechercher un diagnostique"}
          bg={"#fff"}
          h={20}
          radius={10}
          mb={20}
          onChange={(e) => setQuery(e.target.value.toLowerCase())}
          value={query}
        />
      </div>
      {diagnosticLoading ? (
        <Loader type={true} />
      ) : diagnostiqueData?.count === 0 ? (
        "Pas de diagnostique"
      ) : (
        diagnostiqueData?.data
          .filter(
            (item) =>
              item?.student?.firstName
                .toLowerCase()
                .includes(query.toLowerCase()) ||
              item?.student?.lastName
                .toLowerCase()
                .includes(query.toLowerCase())
          )
          .map((item) => (
            <div
              className=""
              key={item?.id}
              style={{
                backgroundColor: "#fff",
                padding: "1rem",
                borderRadius: 10,
                marginBottom: 10,
              }}
              onClick={() =>
                navigation(`${item?.id}`, {
                  state: {
                    eleveId: item?.student?.id,
                    seuil: item?.seuil,
                    diagnosticId: item?.id,
                  },
                })
              }
            >
              <HacFlex position={"apart"}>
                <HacText>{item?.diagnostic?.title}</HacText>
                <div className="">
                  <HacText>
                    {item?.date_diagnostic.split("-").reverse().join("-")}
                  </HacText>
                  <HacText size={10} mt={2}>
                    {item?.status}
                  </HacText>
                </div>
              </HacFlex>
              <HacFlex gap={10}>
                <User size={18} />
                <HacFlex gap={10} mb={-5}>
                  <HacText>{item?.student?.firstName}</HacText>
                  <HacText>{item?.student?.lastName}</HacText>
                </HacFlex>
              </HacFlex>
              {/* <HacText>{item?.seuil}</HacText> */}
              {/* <HacButton
                  radius={15}
                  mt={5}
                  h={15}
                  onClick={() =>
                    navigation(`${item?.id}`, {
                      state: {
                        // matiereId: item?.matiereId,
                        // niveauId: item?.niveauId,
                        // seuil: item?.seuil,
                        // diagnosticId: item?.id,
                        // date,
                      },
                    })
                  }
                >
                  faire les eleves de ce diagnostique
                </HacButton> */}
            </div>
          ))
      )}
    </Template>
  );
}

export default HistoriqueListeDiagnostique;
