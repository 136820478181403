import HacDivider from "components/HacDivider";
import HacFlex from "components/HacFlex";
import HacInputText from "components/HacInputText";
import HacText from "components/HacText";
import Loader from "components/Loader";
import { bgColorPrimary } from "constants";
// import { bgColorPrimary } from "constants";
import useGetUserInfo from "hooks/useGetUserInfo";
import { Template } from "layouts/Template";
import HeaderDiagnostique from "pages/testDePositionnement/HeaderDiagnostique";
import React, { useState } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAddDiagnostiqueEleveTentativeMutation,
  useGetDiagnostiqueQuery,
} from "redux/features/diagnostiqueApi";
import { ArrowRight, Loader2 } from "tabler-icons-react";
// import { useGetMatieresQuery } from "redux/features/matiereApi";

function ListDiagnostique() {
  const { classeId, csrfToken, userId, eleveId } = useGetUserInfo();
  const navigation = useNavigate();
  const location = useLocation();

  // console.log(location?.state);

  const dateNow = new Date();
  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState(
    `${dateNow.getFullYear()}-${(dateNow.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${dateNow.getDate().toString().padStart(2, "0")}`
  );

  const { data, isLoading } = useGetDiagnostiqueQuery({
    classeId,
    matiereId: location?.state.matiereId,
  });
  const [addDiagnostiqueEleveTentative] =
    useAddDiagnostiqueEleveTentativeMutation();

  const handleCreateEleveDiagnostique = async (item) => {
    setLoader(true);
    try {
      const res = await addDiagnostiqueEleveTentative({
        body: {
          date_diagnostic: date,
          diagnosticId: item?.id,
          studentId: eleveId,
          coachId: userId,
        },
        token: csrfToken,
      });

      if (res?.error) {
        return alert("use erreur est sur venue");
      }

      // console.log({ res });

      navigation(`${item?.id}`, {
        state: {
          matiereId: item?.matiereId,
          niveauId: item?.niveauId,
          seuil: item?.seuil,
          diagnosticId: item?.id,
          date,
          tentativeId: res?.data?.id,
        },
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setLoader(false);
    }
  };

  return (
    <Template
      mainStyle={{ backgroundColor: "rgba(0,0,0,0.1)" }}
      topBar={<HeaderDiagnostique title={"Liste des diagnostics"} />}
    >
      <div className="">
        <div className="" style={{ padding: ".5rem" }}>
          <HacText mt={10} mb={10}>
            Choisir une date
          </HacText>
          <div
            className=""
            style={{ position: "sticky", top: 10, marginBottom: 30 }}
          >
            <HacInputText
              type="date"
              onChange={(e) => {
                setDate(e.target.value);
              }}
              value={date}
              placeholder={"Choisir une date"}
              bg={"#fff"}
              // h={50}
              radius={10}
              mt={7}
              icon={<FaCalendarAlt />}
              styleInput={{ border: 0 }}
            />
          </div>

          <HacDivider my={10} color={"rgba(0,0,0,0.1)"} />

          <HacText mb={10} mt={15}>
            Sélectionner un niveau
          </HacText>
          {isLoading ? (
            <Loader type={"loader"} />
          ) : data?.count === 0 ? (
            "Pas de diagnostique"
          ) : (
            data?.data
              // .filter((item) => item?.title.toLowerCase().includes(query))
              // .filter((item) =>
              //   item?.title
              //     .toLowerCase()
              //     .includes(location?.state?.matiere.toLowerCase())
              // )
              .map((item, index) => (
                <div
                  className=""
                  key={item?.id}
                  style={{
                    backgroundColor: "#fff",
                    padding: "1rem",
                    borderRadius: 10,
                    marginBottom: 15,
                    position: "relative",
                    cursor: loader ? "not-allowed" : "pointer",
                    opacity: loader ? 0.8 : 1,
                    // overflow: "hidden",
                    // height: 60,
                  }}
                  // onClick={() =>
                  //   loader ? null : handleCreateEleveDiagnostique(item)
                  // }
                  onClick={() =>
                    navigation(`${item?.id}`, {
                      state: {
                        matiereId: item?.matiereId,
                        niveauId: item?.niveauId,
                        seuil: item?.seuil,
                        diagnosticId: item?.id,
                        date,
                      },
                      replace: true,
                    })
                  }
                >
                  <div
                    className=""
                    style={{
                      position: "absolute",
                      width: 5,
                      backgroundColor: bgColorPrimary,
                      height: "60%",
                      borderRadius: 20,
                      left: 0,
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  ></div>
                  <HacFlex position={"apart"}>
                    <div className="">
                      <div
                        className=""
                        style={{
                          backgroundColor: "rgba(0,0,0,0.05)",
                          width: 100,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 10,
                          padding: "3px 0",
                          marginBottom: 5,
                          fontWeight: "600",
                        }}
                      >
                        {item?.nombreDeNiveaux} niveau
                        {parseInt(item?.nombreDeNiveaux) <= 1 ? "" : "x"}
                      </div>
                      <HacText>
                        Diagnostique
                        {item?.title} {index + 1}
                      </HacText>
                    </div>
                    <div
                      style={{
                        backgroundColor: bgColorPrimary,
                        borderRadius: 20,
                        // padding: "1px 5px",
                        height: 20,
                        width: 30,
                        display: "grid",
                        placeItems: "center",
                      }}
                    >
                      {loader ? (
                        <Loader2
                          size={15}
                          color="#fff"
                          className="myLoaderRounded"
                        />
                      ) : (
                        <ArrowRight size={18} color="#fff" />
                      )}
                    </div>
                  </HacFlex>
                </div>
              ))
          )}
        </div>
      </div>
    </Template>
  );
}

export default ListDiagnostique;
