import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API } from "routes/api";

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API,
  }),
  refetchOnReconnect: true,
  keepUnusedDataFor: 604800,
  reducerPath: "genimi-diagnostic",
  endpoints: () => ({}),
});

export const ApiMananger = baseApi.enhanceEndpoints({
  addTagTypes: [
    "Coachs",
    "Parents",
    "Fiches",
    "Users",
    "Eleves",
    "Appels",
    "comprehensions",
    "Paiements",
    "Evaluations",
    "Niveaux",
    "Classes",
    "Diagnostics",
  ],
});
