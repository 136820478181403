import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { baseApi } from "redux/features/apiMananger";
import userIdReducer from "redux/features/userSlice";
import diagnostiqueReducer from "redux/features/diagnostiqueSlice";

const persistConfig = {
  key: "genimiDiagnostique",
  storage,
};

const rootReducer = combineReducers({
  dataPersisted: persistReducer(
    persistConfig,
    combineReducers({ user: userIdReducer, diagnostic: diagnostiqueReducer })
  ),
  [baseApi.reducerPath]: baseApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat([baseApi.middleware]);
  },
  devTools: true,
});

export const persistor = persistStore(store);
