import { ApiMananger } from "./apiMananger";

const classeApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getClasses: build.query({
      query: () => `/classes/main/`,
      providesTags: ["Classes"],
      provides: { data: true },
      cacheTime: 24 * 60 * 60 * 1000, // Durée en millisecondes (1 jour)
      staleTime: 60 * 60 * 1000, // Durée en millisecondes (1 heure)
    }),
    getClassesAll: build.query({
      query: () => `/classes/`,
      providesTags: ["Classes"],
      provides: { data: true },
      cacheTime: 24 * 60 * 60 * 1000, // Durée en millisecondes (1 jour)
      staleTime: 60 * 60 * 1000, // Durée en millisecondes (1 heure)
    }),
  }),
});

export const { useGetClassesQuery } = classeApi;
