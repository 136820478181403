import HacFlex from "components/HacFlex";
import HacText from "components/HacText";
import RenderData from "components/RenderData";
import React from "react";

function Tentative({ eleve, data = [], isLoading, onClick }) {
  return (
    <div
      className=""
      style={{
        borderRadius: 10,
        padding: ".5rem 0",
        maxHeight: "100%",
        overflow: "hidden",
        backgroundColor: "#fff",
      }}
    >
      <div
        className=""
        style={{
          backgroundColor: "#fff",
          borderRadius: 10,
          overflowY: "auto",
          maxHeight: "100%",
          padding: "1rem",
        }}
      >
        <span className="text-md">
          Historique des tentatives de{" "}
          <span className="font-bold">
            {eleve?.firstName}
            {eleve?.lastName}
          </span>
        </span>
        <div
          className=""
          style={{
            width: "100%",
            height: 1,
            backgroundColor: "rgba(0,0,0,0.1)",
            margin: "10px 0",
          }}
        ></div>

        <RenderData
          isLoading={isLoading}
          data={data}
          render={(history, index) => (
            <div
              key={history?.id}
              className=""
              style={{ marginBottom: 15, cursor: "pointer" }}
              onClick={() => onClick(history)}
            >
              <HacFlex position={"apart"}>
                <div className="" style={{ width: "100%" }}>
                  <HacFlex position={"apart"}>
                    <HacText size={12} weight={600}>
                      {history?.diagnostic}
                    </HacText>
                    <HacText weight={700} size={11} color={"green"}>
                      {history?.count} tentative
                      {history?.count > 1 ? "s" : ""}
                    </HacText>
                  </HacFlex>

                  <HacText weight={700} size={11} mt={10}>
                    Dernière tentative :{" "}
                    <span className="" style={{ color: "orange" }}>
                      {new Date(history?.last_date).toLocaleDateString("fr", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                        // hour: "numeric",
                        // minute: "numeric",
                      })}
                    </span>
                  </HacText>
                </div>
              </HacFlex>

              {index + 1 === data.length ? null : (
                <div
                  className=""
                  style={{
                    width: "100%",
                    height: 1,
                    backgroundColor: "rgba(0,0,0,0.1)",
                    margin: "15px 0",
                  }}
                ></div>
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
}

export default Tentative;
