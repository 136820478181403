import React from "react";

function SafeArea({ bg, children, color }) {
  return (
    <div
      style={{
        position: "relative",
        minHeight: "100dvh",
        flex: 1,
        background: bg,
        color: color,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {children}
    </div>
  );
}

export default SafeArea;
