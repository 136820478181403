import styles from "assets/styles.module.css";
import React from "react";
import { IconMoodEmpty } from "@tabler/icons-react";
import HacText from "./HacText";

function Empty({ title = "La liste est vide" }) {
  return (
    <div className={styles.center}>
      <IconMoodEmpty
        size={"5rem"}
        style={{ marginTop: -100 }}
        color="rgba(0,0,0,0.5)"
      />
      <HacText size={18} mt={20}>
        {title}
      </HacText>
    </div>
  );
}

export default Empty;
