import React from "react";

/**
 *
 * @param {
 *  {
 *    type: String,
 *    name: String,
 *    value: String | Number,
 *    onChange: Function,
 *    label: String,
 *    errorMessage: String,
 *    icon: {
 *      name: String,
 *      color: String,
 *      size: Number
 *    },
 *    style: {},
 *    inputContainerStyle: {}
 *    height: Number,
 *    extraOptions: {}
 *  }
 * } param0
 * @returns
 */
function Input({
  type,
  value,
  onChange,
  label,
  errorMessage,
  icon,
  style,
  inputStyle,
  inputContainerStyle,
  height,
  extraOptions,
  name = "",
  key = 0,
}) {
  return (
    <div style={{ ...style }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          backgroundColor: "#fff",
          borderRadius: 5,
          border: errorMessage
            ? "0.7px solid red"
            : "0.7px solid rgba(0,0,0,0.1)",
          height: height ?? 40,
          overflow: "hidden",
          ...inputContainerStyle,
        }}
      >
        {icon && (
          <icon.name
            color={icon?.color ?? "gray"}
            style={{ marginLeft: 12 }}
            size={icon?.size ?? 15}
          />
        )}
        <input
          key={key}
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            marginRight: 10,
            paddingLeft: 15,
            ...inputStyle,
          }}
          placeholder={label}
          type={type ?? "text"}
          value={value}
          onChange={(e) => onChange(e.target.value, name)}
          {...extraOptions}
        />
      </div>
      {/* {errorMessage && (
        <Text
          style={{
            marginTop: 1,
            position: "absolute",
            fontSize: 13,
            color: "red",
          }}
        >
          {errorMessage}
        </Text>
      )} */}
    </div>
  );
}

export default Input;
