import useGetUserInfo from "hooks/useGetUserInfo";
// import styles from "assets/styles.module.css";
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
// import Drawer from "./Drawer";

/**
 * RequireAuth est un composant de navigation qui permet de protéger les routes qui nécessitent une authentification.
 * Si l'utilisateur est authentifié, le composant rend le contenu de la page, sinon il redirige l'utilisateur vers la page de connexion.
 */
function RequireAuth() {
  const { userId } = useGetUserInfo();

  const location = useLocation();

  return userId ? (
    <>
      {/* <Header /> */}
      {/* <div className={styles.appelContainer}> */}
      <Outlet />
      {/* </div> */}
      {/* <BottomTab /> */}
      {/* {schoolId && connection?.access && <BottomTab />} */}
      {/* <Drawer /> */}
    </>
  ) : (
    <Navigate to={"/login"} state={{ from: location }} replace />
  );
}

export default RequireAuth;
