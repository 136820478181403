import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { publicRoutes, privateRoutes } from "routes/app.routes";
import RequireAuth from "components/RequireAuth";
import useGetUserInfo from "hooks/useGetUserInfo";

const recursiveRoutes = (routes) => {
  return (
    <>
      {routes.map((route, index) => {
        if (route.subPath) {
          return (
            <Route path={route.path} element={route.element} key={index}>
              {recursiveRoutes(route.subPath)}
            </Route>
          );
        }
        return <Route path={route.path} element={route.element} key={index} />;
      })}
    </>
  );
};

function Root() {
  const { userId } = useGetUserInfo();

  return (
    <Routes>
      {publicRoutes.map((item, index) => (
        <Route
          path={item?.path}
          element={userId ? <Navigate to={"/"} /> : item?.element}
          key={index}
        />
      ))}

      <Route element={<RequireAuth />}>{recursiveRoutes(privateRoutes)}</Route>
    </Routes>
  );
}

export default Root;
