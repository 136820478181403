import React from "react";
import styles from "../assets/styles.module.css";
import Text from "./Text";

function HacInputText({
  placeholder,
  onChange,
  value = "",
  label,
  type = "text",
  icon,
  style,
  radius,
  name,
  mt,
  mb,
  ml,
  mr,
  mx,
  my,
  w,
  h,
  bg,
  styleInput,
}) {
  return (
    <div
      className=""
      style={{
        marginTop: mt ?? my,
        marginBottom: mb ?? my,
        marginLeft: ml ?? mx,
        marginRight: mr ?? mx,
        borderRadius: radius,
        backgroundColor: bg,
      }}
    >
      {label && <Text mb={5}>{label}</Text>}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "nowrap",
          gap: 10,
          height: h,
          width: w,
          ...styleInput,
        }}
        className={styles.input}
      >
        {icon && icon}
        <input
          style={{
            all: "unset",
            height: "100%",
            width: "100%",
          }}
          type={type}
          placeholder={placeholder ?? "recherhcer"}
          // style={{ ...style, width: w }}
          value={value}
          onChange={onChange}
          name={name}
        />
      </div>
    </div>
  );
}

export default HacInputText;
