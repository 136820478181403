import React from "react";
import Loader from "./Loader";

function RenderData({ isLoading = false, data = [], render, renderEmpty }) {
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : !data || (Array.isArray(data) && data.length === 0) ? (
        renderEmpty ?? "Pas de données"
      ) : Array.isArray(data) ? (
        data?.map(render)
      ) : (
        render(data)
      )}
    </>
  );
}

export default RenderData;
