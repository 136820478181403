import { ApiMananger } from "./apiMananger";

const parentApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getParent: build.query({
      query: (id) => `/parents/${id}/`,
      providesTags: ["Parents"],
    }),
    getParents: build.query({
      query: () => `/parents/`,
      providesTags: ["Parents"],
      provides: { data: true },
      cacheTime: 24 * 60 * 60 * 1000, // Durée en millisecondes (1 jour)
      staleTime: 60 * 60 * 1000, // Durée en millisecondes (1 heure)
    }),
    addParent: build.mutation({
      query(body) {
        return {
          url: `/Parents`,
          method: "POST",
          headers: {},
          body,
        };
      },
      invalidatesTags: ["Parents"],
    }),
    updateParent: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `Parents/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Parents"],
    }),
  }),
});

export const {
  useAddParentMutation,
  useGetParentQuery,
  useGetParentsQuery,
  useUpdateParentMutation,
} = parentApi;
