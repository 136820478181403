import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import styles from "assets/styles.module.css";
import {
  useAddDiagnostiqueEleveTentativeMutation,
  useAddDiagnostiqueMutation,
  // useGetDiagnostiqueByMatierByClasseByNiveauQuery,
  useGetEpreuvexByDiagnostiqueQuery,
  useGetEpreuvexByDiagnostiqueWithNiveauQuery,
  useUpdateiagnostiqueEleveTentativeMutation,
} from "redux/features/diagnostiqueApi";
import Button from "components/Button";
import Input from "components/Input";
import { FaArrowLeft, FaWindowClose } from "react-icons/fa";
import { bgColorPrimary } from "constants";
import useGetUserInfo from "hooks/useGetUserInfo";
import HacText from "components/HacText";
import Empty from "components/Empty";
// import { toast } from "react-toastify";
import HacFlex from "components/HacFlex";
import Loader from "components/Loader";
import { Template } from "layouts/Template";
import HeaderDiagnostique from "pages/testDePositionnement/HeaderDiagnostique";
import { useGetMatieresQuery } from "redux/features/matiereApi";
import toast from "react-hot-toast";

function ExerciceDiagnostique() {
  // const params = useParams();
  const navigation = useNavigate();
  const location = useLocation();

  const { userId, csrfToken, eleveId } = useGetUserInfo();
  const { data: matieres } = useGetMatieresQuery();

  // const [updateiagnostiqueEleveTentative, { isLoading: updateLoading }] =
  //   useUpdateiagnostiqueEleveTentativeMutation();
  const [addDiagnostiqueEleveTentative, { isLoading: addTentaticeLoading }] =
    useAddDiagnostiqueEleveTentativeMutation();

  const [current, setCurrent] = useState(0);
  const [currentNiveau, setCurrentNiveau] = useState(0);
  const [end, setEnd] = useState(false);

  // const [currentNiveau, setCurrentNiveau] = useState(
  //   parseInt(location?.state?.niveauId)
  // );

  const [dLoading, setDLoading] = useState(false);
  // const [dNiveauLoading, setDNiveauLoading] = useState(false);

  // const { data, isLoading } = useGetDiagnostiqueQuery();
  const [formError, setFormError] = useState(null);
  const [form, setForm] = useState({
    data: [],
  });

  // const { data, isLoading } = useGetDiagnostiqueByMatierByClasseByNiveauQuery(
  //   {
  //     matiereId: parseInt(params?.matiereId),
  //     niveauId: currentNiveau,
  //     classeId,
  //   },
  //   {
  //     refetchOnFocus: true,
  //   }
  // );

  // console.log({ location });

  const { data, isLoading } = useGetEpreuvexByDiagnostiqueQuery(
    location?.state?.diagnosticId,
    {
      refetchOnFocus: true,
    }
  );

  const { data: diagnosticDataNiveau } =
    useGetEpreuvexByDiagnostiqueWithNiveauQuery(location?.state?.diagnosticId, {
      refetchOnFocus: true,
    });

  let getDiagnostique = diagnosticDataNiveau
    ? [...diagnosticDataNiveau?.data].sort(
        (a, b) => a.niveau.split(" ")[1] - b.niveau.split(" ")[1]
      )
    : [];

  // const [addDiagnostique, { isLoading: addLoading }] =
  //   useAddDiagnostiqueMutation();

  const handleSendDiagnostique = async (type) => {
    // return console.log({ form });
    const notes = form?.data?.map((note) => ({
      epreuveId: note?.epreuveId,
      note: note?.note,
    }));

    const body = {
      notes,
      diagnosticId: parseInt(location?.state?.diagnosticId),
      coachId: userId,
      studentId: eleveId,
    };

    // return console.log({ body });
    setDLoading(true);

    try {
      // for (let i = 0; i < form?.data.length; i++) {
      //   await addDiagnostique({
      //     body: form?.data[i],
      //     token: csrfToken,
      //   });
      // }
      await addDiagnostiqueEleveTentative({
        body: { ...body, status: type || "VALIDE" },
        token: csrfToken,
      });
      if (type === "VALIDE") {
        toast.success("Diagnostique terminer.", {
          style: { margin: "10px", borderRadius: 10 },
        });
      } else {
        toast.warning("Diagnostique abandonne.", {
          style: { margin: "10px", borderRadius: 10 },
        });
      }
      // await updateiagnostiqueEleveTentative({
      //   body: { status: "VALIDE", id: location?.state?.tentativeId },
      //   token: csrfToken,
      // });
      navigation(-1, { replace: true });
    } catch (error) {
      toast.error("Une erreur c'est produite", {
        style: { margin: "10px", borderRadius: 10 },
      });
    } finally {
      setDLoading(false);
    }
  };

  const handleNextNiveau = async () => {
    // return console.log({ form });

    // const notes = form.map((note) => ({
    //   epreuveId: note?.epreuveId,
    //   note: note?.note,
    // }));

    // return console.log({ notes });
    // setDLoading(true);

    try {
      // for (let i = 0; i < form?.data.length; i++) {
      //   const res = await addDiagnostique({
      //     body: form?.data[i],
      //     token: csrfToken,
      //   });

      //   // console.log({ res });
      // }
      setCurrent(0);
      setCurrentNiveau((v) => v + 1);
      setEnd(false);
      // setForm({ data: [] });
      toast.success("Bienvenue dans le niveau 2");
      // toast.success(
      //   "Diagnostique en " +
      //     matieres?.data.find(
      //       (niveau) => niveau?.id === parseInt(location?.state?.matiereId)
      //     )?.name +
      //     " reussi.",
      //   {
      //     style: { margin: "10px", borderRadius: 10 },
      //   }
      // );
      // navigation(-1, { replace: true });
    } catch (error) {
      toast.error("Une erreur c'est produite", {
        style: { margin: "10px", borderRadius: 10 },
      });
    } finally {
      setDLoading(false);
    }
  };

  const handleNext = () => {
    setCurrent((v) => (data?.data.length === v + 1 ? v : v + 1));
  };

  const handlePrev = () => {
    if (current === 0) return;
    setEnd(false);
    setCurrent((v) => (v === 0 ? v : v - 1));
    if (!form?.data[current]?.note) setFormError("");
  };

  // console.log(getDiagnostique);

  return (
    <Template
      mainStyle={{ backgroundColor: "rgba(0,0,0,0.1)" }}
      topBar={
        <HeaderDiagnostique
          title={
            <HacText weight={600}>
              {"Diagnostique en " +
                matieres?.data.find(
                  (niveau) =>
                    niveau?.id === parseInt(location?.state?.matiereId)
                )?.name ?? null}
            </HacText>
          }
        />
      }
      bottomBarStyle={{ backgroundColor: "rgba(0,0,0,0.1)" }}
      bottomBar={
        !isLoading && data?.data.length > 0 ? (
          <HacFlex
            gap={10}
            position={"apart"}
            h={"100%"}
            style={{ padding: "0 1rem" }}
          >
            {end ? (
              <>
                <Button
                  title={"Terminer"}
                  onClick={() => handleSendDiagnostique("VALIDE")}
                  style={{
                    backgroundColor: bgColorPrimary,
                    color: "#fff",
                    height: 35,
                    flex: 1,
                  }}
                  loading={addTentaticeLoading}
                />
                {diagnosticDataNiveau?.data.length ===
                currentNiveau + 1 ? null : getDiagnostique[currentNiveau + 1]
                    .data.length === 0 ? null : getDiagnostique[currentNiveau]
                    ?.seuil <=
                  form.data
                    .filter(
                      (note) =>
                        note?.niveau === getDiagnostique[currentNiveau]?.niveau
                    )
                    .reduce((acc, cur) => acc + cur?.note, 0) ? (
                  <Button
                    title={"Niveau suivant"}
                    onClick={handleNextNiveau}
                    style={{
                      backgroundColor: "green",
                      color: "#fff",
                      height: 35,
                      flex: 1,
                    }}
                  />
                ) : null}
              </>
            ) : (
              <>
                <Button
                  title={"Abandonner"}
                  // onClick={async () => {
                  //   toast.warning("Diagnostique abandonne.", {
                  //     style: { margin: "10px", borderRadius: 10 },
                  //   });
                  //   // navigation("/ecoles/eleves/eleve", { replace: true });
                  //   await updateiagnostiqueEleveTentative({
                  //     body: {
                  //       status: "NON_EVALUE",
                  //       id: location?.state?.tentativeId,
                  //     },
                  //     token: csrfToken,
                  //   });
                  //   navigation(-1, { replace: true });
                  // }}
                  onClick={() => handleSendDiagnostique("VALIDE")}
                  style={{
                    backgroundColor: "red",
                    color: "#fff",
                    height: 35,
                    flex: 1,
                  }}
                  loading={addTentaticeLoading}
                />

                <Button
                  title={
                    getDiagnostique[currentNiveau]?.data.length === current + 1
                      ? "Terminer"
                      : "Suivant"
                  }
                  type={"button"}
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      getDiagnostique[currentNiveau]?.data.length ===
                      current + 1
                    ) {
                      // navigation("finish");
                      if (
                        form?.data[current]?.note !== "" &&
                        form?.data[current]?.note !== undefined
                      ) {
                        // handleSendDiagnostique();
                        setEnd(true);
                      } else {
                        setFormError("Il faut d'abord mettre la notes");
                      }
                    } else {
                      if (!formError) {
                        if (
                          form?.data[current]?.note !== "" &&
                          form?.data[current]?.note !== undefined
                        ) {
                          handleNext();
                        } else {
                          setFormError("Il faut d'abord mettre la notes");
                        }
                      }
                    }
                  }}
                  style={{
                    backgroundColor: bgColorPrimary,
                    color: "#fff",
                    height: 35,
                    flex: 1,
                  }}
                  loading={addTentaticeLoading}
                />
              </>
            )}
          </HacFlex>
        ) : null
      }
    >
      <div className="">
        {isLoading ? (
          <Loader type={true} />
        ) : getDiagnostique.length > 0 ? (
          // getDiagnostique.map((item, index) => (
          <div>
            <div
              className=""
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "1rem",
              }}
            >
              {current === 0 ? (
                <div></div>
              ) : (
                <FaArrowLeft size={20} onClick={handlePrev} />
              )}
              <HacText size={16} weight={600}>
                {/* {niveaux?.data.find(
                    (niveau) =>
                      niveau?.id === parseInt(location?.state?.niveauId)
                  )?.name ?? "Pas de niveau"} */}
                {getDiagnostique[currentNiveau]?.niveau}
                {/* {current + 1} */}
              </HacText>
              <HacText weight={600}>
                {current + 1} / {getDiagnostique[currentNiveau]?.data.length}
              </HacText>
            </div>
            <div className="" style={{ padding: "1rem" }}>
              <div
                className=""
                style={{
                  height: 10,
                  borderRadius: 10,
                  overflow: "hidden",
                  position: "relative",
                  backgroundColor: "rgba(0,0,0,0.06)",
                  border: "1px solid rgba(0,0,0,0.06)",
                }}
              >
                <div
                  className={"pourcentage-diagnostique"}
                  style={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    border: "1px solid rgba(0,0,0,0.01)",
                    left: 0,
                    top: 0,
                    height: "100%",
                    backgroundColor: "green",
                    width: `${
                      getDiagnostique[currentNiveau]?.data.length > 0
                        ? ((end ? current + 1 : current) * 100) /
                          getDiagnostique[currentNiveau]?.data.length
                        : 0
                    }%`,
                  }}
                >
                  <HacText size={7.5} color={"#fff"} weight={600}>
                    {isLoading
                      ? "..."
                      : getDiagnostique[currentNiveau]?.data.length > 0
                      ? `${parseInt(
                          ((end ? current + 1 : current) * 100) /
                            getDiagnostique[currentNiveau]?.data.length
                        )}%`
                      : "..."}
                  </HacText>
                </div>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                height: "100%",
                // backgroundColor: "red",
                padding: ".5rem",
                marginTop: 10,
              }}
            >
              {formError && (
                <HacFlex
                  position={"apart"}
                  className=""
                  style={{
                    borderRadius: 10,
                    padding: ".5rem",
                  }}
                  bg={"rgba(255,2,0, 0.3)"}
                  // bg={"rgba(255,204,0, 0.7)"}
                  mb={10}
                  mt={-15}
                >
                  <HacText color={"#fff"} weight={400}>
                    {formError}
                  </HacText>
                  <FaWindowClose onClick={() => setFormError("")} />
                </HacFlex>
              )}
              {end ? (
                <div className="">
                  <HacText ta={"center"} size={20}>
                    Diagnostique terminer
                  </HacText>
                  <HacText ta={"center"} size={20} mt={10} weight={800}>
                    Seuil: {getDiagnostique[currentNiveau]?.seuil}
                  </HacText>
                  <HacText ta={"center"} size={35} weight={600} mt={10}>
                    {form.data
                      .filter(
                        (note) =>
                          note?.niveau ===
                          getDiagnostique[currentNiveau]?.niveau
                      )
                      .reduce((acc, cur) => acc + cur?.note, 0)}{" "}
                    /{" "}
                    {getDiagnostique[currentNiveau]?.data.reduce(
                      (acc, cur) => acc + cur?.bareme,
                      0
                    )}
                  </HacText>
                  <HacText ta={"center"} mt={10} size={18}>
                    {getDiagnostique[currentNiveau]?.seuil <=
                    form?.data
                      .filter(
                        (note) =>
                          note?.niveau ===
                          getDiagnostique[currentNiveau]?.niveau
                      )
                      .reduce((acc, cur) => acc + cur?.note, 0)
                      ? "L'élève  a réussi ce niveau."
                      : "L'élève n'a pas réussi ce niveau."}
                  </HacText>
                </div>
              ) : (
                getDiagnostique[currentNiveau]?.data.map(
                  (item, index) =>
                    current === index && (
                      <div
                        style={{
                          background: "#EBEFF2",
                          padding: "1rem",
                          borderRadius: 10,
                        }}
                        key={item?.id}
                      >
                        <div
                          className=""
                          style={{ marginTop: 10, fontSize: "1.2rem" }}
                        >
                          <HacText>
                            {
                              getDiagnostique[currentNiveau]?.data[current]
                                ?.titre
                            }
                          </HacText>
                          <div
                            style={{
                              fontSize: 15,
                              fontWeight: 500,
                              marginTop: 15,
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                getDiagnostique[currentNiveau]?.data[current]
                                  ?.sujet,
                            }}
                          ></div>
                        </div>
                        <div
                          className=""
                          style={{ fontSize: "1.1rem", marginTop: 10 }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                getDiagnostique[currentNiveau]?.data[current]
                                  ?.correction ||
                                getDiagnostique[currentNiveau]?.data[current]
                                  ?.correction_dp,
                            }}
                          ></div>
                        </div>

                        <form
                          className=""
                          style={{
                            marginTop: 10,
                            justifyContent: "flex-end",
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                          }}
                          onSubmit={(e) => {
                            e.preventDefault();
                            // if (!formError) {
                            //   if (
                            //     form?.data[current]?.note !== "" &&
                            //     form?.data[current]?.note !== undefined
                            //   ) {
                            //     handleNext();
                            //   } else {
                            //     setFormError("Il faut d'abord mettre la note");
                            //   }
                            // }
                          }}
                        >
                          <HacText size={17} weight={600}>
                            Note sur {item.bareme}
                          </HacText>
                          <Input
                            label={"note"}
                            type={"number"}
                            required
                            errorMessage={formError ?? ""}
                            onChange={(value) => {
                              // console.log({
                              //   epreuveId: parseInt(item?.id),
                              //   note: value !== "" && parseInt(value),
                              //   diagnostic: parseInt(
                              //     location?.state?.diagnosticId
                              //   ),
                              //   coachId: userId,
                              //   eleveId: eleveId,
                              //   date_diagnostic: location?.state?.date,
                              //   epreuveDiagnostic: location?.state?.tentativeId,
                              // });
                              if (item?.bareme < value) {
                                setFormError(
                                  "La valeur mise est supperieur au bareme"
                                );
                              } else {
                                setFormError("");
                              }
                              return setForm({
                                ...form,
                                data: [
                                  ...form?.data.filter(
                                    (val) => val.epreuveId !== item?.id
                                  ),
                                  {
                                    epreuveId: parseInt(item?.id),
                                    note: value !== "" && parseInt(value),
                                    diagnostic: parseInt(
                                      location?.state?.diagnosticId
                                    ),
                                    niveau:
                                      getDiagnostique[currentNiveau]?.niveau,
                                    coachId: userId,
                                    eleveId: eleveId,
                                    date_diagnostic: location?.state?.date,

                                    // Le tentativeId
                                    epreuveDiagnostic:
                                      location?.state?.tentativeId,
                                  },
                                ],
                              });
                            }}
                            style={{ width: 100 }}
                            height={30}
                            inputStyle={{ borderRadius: 10 }}
                            value={
                              form?.data.find(
                                (_item) => _item.epreuveId === item?.id
                              )?.note ?? ""
                            }
                          />
                        </form>
                      </div>
                    )
                )
              )}
            </div>
          </div>
        ) : (
          // ))
          <Empty title="Pas d'exercices" />
        )}
        <div className="" style={{ paddingBottom: 140 }}></div>
      </div>
    </Template>
  );
}

export default ExerciceDiagnostique;
