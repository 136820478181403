import { IconLogout } from "@tabler/icons-react";
import { AuthContext } from "context/AuthContext";
import React, { useContext } from "react";

function Deconnexion() {
  const { logout } = useContext(AuthContext);

  return (
    <div
      style={{
        // color: "#fff",
        // backgroundColor: "rgba(0,0,0,0.4)",
        display: "grid",
        placeItems: "center",
        borderRadius: "50%",
        width: 40,
        height: 40,
        cursor: "pointer",
      }}
      onClick={logout}
    >
      <IconLogout size={20} />
    </div>
  );
}

export default Deconnexion;
