import Empty from "components/Empty";
import HacFlex from "components/HacFlex";
import HacInputText from "components/HacInputText";
import HacText from "components/HacText";
import Loader from "components/Loader";
import HistoriqueItems from "components/historique";
import { Template } from "layouts/Template";
import HeaderDiagnostique from "pages/testDePositionnement/HeaderDiagnostique";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useGetDiagnostiqueNoteByEleveQuery,
  useGetDiagnostiqueTentavivesByElevesGroupeByTententativeQuery,
} from "redux/features/diagnostiqueApi";

function HistoriqueListeEleves() {
  const location = useLocation();
  // const navigation = useNavigate();
  const params = useParams();
  const navigation = useNavigate();

  const [query, setQuery] = useState("");

  // const { data: dianostiqueData, isLoading: diagnostiqueLoading } =
  //   useGetDiagnostiqueNoteByEleveQuery({
  //     eleveId: parseInt(location?.state?.eleveId),
  //     diagnosticId: parseInt(location?.state?.diagnosticId),
  //   });

  // console.log({ dianostiqueData, location });

  const { data, isLoading } =
    useGetDiagnostiqueTentavivesByElevesGroupeByTententativeQuery({
      eleveId: parseInt(location?.state?.eleveId),
      diagnosticId: parseInt(params?.diagnostiqueId),
    });

  return (
    <Template
      topBar={<HeaderDiagnostique title={"Les exercices que l'eleve a fait"} />}
      mainStyle={{ backgroundColor: "rgba(0,0,0,0.1)", padding: ".5rem" }}
    >
      {/* lalalal */}
      <div className="" style={{ position: "sticky", top: 10 }}>
        <HacInputText
          placeholder={"Rechercher un diagnostique"}
          bg={"#fff"}
          h={20}
          radius={10}
          mb={20}
          onChange={(e) => setQuery(e.target.value.toLowerCase())}
          value={query}
        />
      </div>
      {/* {diagnostiqueLoading ? (
        <Loader type={true} />
      ) : dianostiqueData?.data.length > 0 ? (
        dianostiqueData?.data
          // .filter(
          //   (eleve) =>
          //     eleve?.firstName.toLowerCase().includes(query) ||
          //     eleve?.lastName.toLowerCase().includes(query)
          // )
          .map((item, index) => (
            <div
              className=""
              style={{
                backgroundColor: "#fff",
                width: "100%",
                padding: ".7rem",
                borderRadius: 10,
                marginBottom: 10,
              }}
              key={index}
              onClick={() => {
                // dispatch(setElevePositionnement(item));
                // dispatch(addClasseId(item?.classeId));
                // navigation("/listdiagnostique", { state: { parentId } });
              }}
            >
              <HacFlex gap={15}>
                <div className="">
                  <HacFlex gap={10}>
                    <HacText weight={600}>Sujet :</HacText>
                    <div
                      className=""
                      dangerouslySetInnerHTML={{ __html: item?.epreuve?.sujet }}
                    ></div>
                  </HacFlex>
                  <HacText mt={10} weight={700} size={15} color={"#003880"}>
                    {item?.note} / {item?.epreuve?.bareme}
                  </HacText>
                </div>
              </HacFlex>
            </div>
          ))
      ) : (
        <Empty />
      )} */}

      <HistoriqueItems
        isLoading={isLoading}
        data={data ? data?.data : []}
        onClick={(id) => navigation(`../detail/${id}`)}
      />
    </Template>
  );
}

export default HistoriqueListeEleves;
