import React from "react";
import { styled } from "styled-components";

const Header = styled.div`
  grid-area: header;
  height: 50px;
`;
const Footer = styled.div`
  grid-area: footer;
  height: 50px;
  // border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const Main = styled.div`
  grid-area: main;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

const Container = styled.div`
  height: 100dvh;
  // width: 100dvw;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-areas:
    "header"
    "main"
    "footer";
  ${(props) =>
    `grid-template-rows: ${props.topBar ? "1fr" : "0fr"} 1fr ${
      props.topBar ? "1fr" : "0fr"
    };`}
`;

/**
 *
 * @param {*} param0
 * @returns
 */
export const Template = (props) => {
  return (
    <Container>
      {props.topBar && (
        <Header style={{ ...props.topBarStyle }}>{props.topBar}</Header>
      )}
      <Main
        style={{
          ...props.mainStyle,
        }}
      >
        {props.children}
      </Main>
      {props.bottomBar && (
        <Footer style={{ ...props.bottomBarStyle }}>{props.bottomBar}</Footer>
      )}
    </Container>
  );
};
