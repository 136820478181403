// import useGetUserInfo from "hooks/useGetUserInfo";
import React, { useState } from "react";
import { useGetElevesWithoutSchoolQuery } from "redux/features/eleveApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setElevePositionnement } from "redux/features/userSlice";
import HacFlex from "components/HacFlex";
import HacText from "components/HacText";
import HacInputText from "components/HacInputText";
import Empty from "components/Empty";
import Loader from "components/Loader";
import { Template } from "layouts/Template";
import HeaderDiagnostique from "pages/testDePositionnement/HeaderDiagnostique";

function EleveWithautSchool() {
  const [query, setQuery] = useState("");

  const { data, isLoading } = useGetElevesWithoutSchoolQuery();

  //   console.log({ data });

  const navigation = useNavigate();
  const dispatch = useDispatch();

  return (
    <Template
      mainStyle={{ backgroundColor: "rgba(0,0,0,0.1)" }}
      topBar={<HeaderDiagnostique title={"Liste des élèves sans parent"} />}
    >
      <div className="">
        <div
          style={{
            padding: ".5rem",
          }}
        >
          <div className="" style={{ position: "sticky", top: 10 }}>
            <HacInputText
              placeholder={"Rechercher un élève"}
              bg={"#fff"}
              h={20}
              radius={10}
              mb={20}
              onChange={(e) => setQuery(e.target.value.toLowerCase())}
              value={query}
            />
          </div>

          {isLoading ? (
            <Loader type={true} />
          ) : data?.data.length > 0 ? (
            [...data?.data]
              .filter(
                (eleve) =>
                  eleve?.firstName.toLowerCase().includes(query) ||
                  eleve?.lastName.toLowerCase().includes(query)
              )
              .map((item) => (
                <div
                  className=""
                  style={{
                    backgroundColor: "#fff",
                    width: "100%",
                    padding: ".7rem",
                    borderRadius: 10,
                    marginBottom: 10,
                  }}
                  key={item?.id}
                  onClick={() => {
                    dispatch(setElevePositionnement(item));
                    navigation("/ecoles/options/diagnostique/eleve");
                  }}
                >
                  <HacFlex gap={15}>
                    <div
                      className=""
                      style={{
                        backgroundColor: "rgba(0,0,0,0.1)",
                        width: 50,
                        height: 50,
                        display: "grid",
                        placeItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <img src="../../icons/faceBlack.png" alt="" width={25} />
                    </div>
                    <div className="">
                      <HacText weight={700} size={15} color={"#003880"}>
                        {item?.firstName} {item?.lastName}
                      </HacText>
                      <HacText mt={3} size={12}>
                        Classe: {item?.classe?.name}
                      </HacText>
                      <HacFlex gap={10} mt={10}>
                        {item?.niveaux.map((niveau, index) => (
                          <div key={niveau}>
                            <HacText size={10} color={"gray"}>
                              {
                                data?.data.find((niv) => niv.id === niveau)
                                  ?.name
                              }{" "}
                              {index + 1 === item?.niveaux.length ? null : "/"}
                            </HacText>
                          </div>
                        ))}
                      </HacFlex>
                    </div>
                  </HacFlex>
                </div>
              ))
          ) : (
            <Empty />
          )}
          <p style={{ height: 10 }}></p>
        </div>
      </div>
    </Template>
  );
}

export default EleveWithautSchool;
