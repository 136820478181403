import React, { useState } from "react";
import Empty from "components/Empty";
import HacFlex from "components/HacFlex";
import HacInputText from "components/HacInputText";
import HacText from "components/HacText";
import { bgColorPrimary } from "constants";
import { useNavigate, useParams } from "react-router-dom";
import { useGetNiveauxQuery } from "redux/features/niveauApi";
import Loader from "components/Loader";
import { Template } from "layouts/Template";
import HeaderDiagnostique from "pages/testDePositionnement/HeaderDiagnostique";
import { FaCalendarAlt } from "react-icons/fa";

export default function SelectNiveau() {
  const navigation = useNavigate();

  const params = useParams();

  const dateNow = new Date();

  const [query, setQuery] = useState("");
  //   const { userId, schoolId } = useGetUserInfo();
  const [select, setSelect] = useState(null);
  const [date, setDate] = useState(
    `${dateNow.getFullYear()}-${(dateNow.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${dateNow.getDate().toString().padStart(2, "0")}`
  );
  // console.log(
  //   `${dateNow.getFullYear()}/${(dateNow.getMonth() + 1)
  //     .toString()
  //     .padStart(2, "0")}/${dateNow.getDate().toString().padStart(2, "0")}`
  // );

  const [ErrorDate, setErrorDate] = useState(null);

  //   const dispatch = useDispatch();

  //   const { data: coach } = useGetCoachQuery(userId, {
  //     refetchOnFocus: true,
  //   });

  const { data, isLoading } = useGetNiveauxQuery();

  // console.log({ matiere: params?.matiere });

  return (
    <Template
      mainStyle={{ backgroundColor: "rgba(0,0,0,0.1)" }}
      topBar={<HeaderDiagnostique title={"Liste des niveaux"} />}
      bottomBarStyle={{
        backgroundColor: "rgba(0,0,0,0.1)",
      }}
      bottomBar={
        <div
          style={{
            display: "grid",
            placeItems: "center",
            width: "100%",
          }}
        >
          <button
            style={{
              width: "90%",
              height: 40,
              borderRadius: 15,
              outline: "none",
              backgroundColor: bgColorPrimary,
              color: "#fff",
              fontWeight: 600,
              opacity: select ? 1 : 0.7,
            }}
            onClick={() => {
              select && !ErrorDate && date && navigation(`${select}`);
            }}
          >
            Continuer
          </button>
        </div>
      }
    >
      <div
        style={{
          padding: ".5rem",
          marginTop: 10,
        }}
      >
        <div className="" style={{ position: "sticky", top: 10 }}>
          <HacInputText
            placeholder={"Rechercher un niveau"}
            bg={"#fff"}
            h={20}
            radius={10}
            mb={20}
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
            value={query}
          />
        </div>
        <div className="">
          {isLoading ? (
            <Loader type={true} />
          ) : data?.data.length > 0 ? (
            [...data?.data]
              .filter((niveau) => niveau?.name.toLowerCase().includes(query))
              .filter((niveau) =>
                niveau?.name
                  .toLowerCase()
                  .includes(params?.matiere.toLowerCase())
              )
              .sort((a, b) => a?.name.split(" ")[1] - b?.name.split(" ")[1])
              .map((item) => (
                <div
                  className=""
                  style={{
                    backgroundColor: "#fff",
                    width: "100%",
                    padding: ".6rem",
                    borderRadius: 10,
                    marginBottom: 10,
                    cursor: "pointer",
                  }}
                  key={item?.id}
                  onClick={() => setSelect(item?.id)}
                >
                  <HacFlex gap={10} wrap="nowrap">
                    <div
                      className=""
                      style={{
                        backgroundColor: "rgba(0,0,0,0.1)",
                        minWidth: 50,
                        minHeight: 50,
                        display: "grid",
                        placeItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <img
                        src={`../../../../../icons/${
                          params?.matiere.toLowerCase() === "lecture"
                            ? "book"
                            : "math"
                        }.png`}
                        alt=""
                        width={25}
                      />
                    </div>
                    <HacFlex className="" position={"apart"} w={"100%"}>
                      <HacText weight={600} size={15} color={"#003880"}>
                        {item?.name}
                      </HacText>
                      {/* <HacText mt={7}>Telephone: {item?.phone}</HacText> */}
                      {/* <HacText>Status: A jour</HacText> */}
                      <div
                        className=""
                        style={{
                          width: 20,
                          height: 20,
                          borderRadius: "50%",
                          backgroundColor:
                            select === item?.id
                              ? "rgba(0,0,250,0.7)"
                              : "rgba(0,0,0,0.1)",
                          border: "2px solid gray",
                        }}
                      ></div>
                    </HacFlex>
                  </HacFlex>
                </div>
              ))
          ) : (
            <Empty />
          )}
          {data?.data.length > 0 && (
            <div className="" style={{ height: 50 }}></div>
          )}
        </div>

        <div className="">
          <HacText>Choisir une date</HacText>
          <HacInputText
            type="date"
            onChange={(e) => {
              setErrorDate(null);
              setDate(e.target.value);
            }}
            value={date}
            // placeholder={"Choisir une date"}
            bg={"#fff"}
            h={20}
            radius={10}
            mt={7}
            icon={<FaCalendarAlt />}
          />
          {ErrorDate && !date && (
            <HacText>Il faut d'abord choisir une date</HacText>
          )}
        </div>
      </div>
    </Template>
    // <SafeArea bg={"rgba(0,0,0,0.1)"}>
    //   <header
    //     style={{
    //       backgroundColor: "#fff",
    //       height: 50,
    //       display: "flex",
    //       width: "100%",
    //       alignItems: "center",
    //       justifyContent: "center",
    //       position: "fixed",
    //       top: 0,
    //       zIndex: 5,
    //       padding: "0 1rem",
    //     }}
    //   >
    //     <HacFlex w={"100%"} position={"apart"}>
    //       <FaArrowLeft size={20} onClick={() => navigation(-1)} />
    //       <h3 style={{}}>Liste des niveaux</h3>
    //       <Deconnexion />
    //     </HacFlex>
    //   </header>

    // <div
    //   style={{
    //     height: "100%",
    //     padding: ".5rem",
    //     paddingTop: 20,
    //     display: "flex",
    //     flexDirection: "column",
    //     overflow: "auto",
    //     gap: 10,
    //     marginTop: 40,
    //   }}
    // >
    //   <HacInputText
    //     placeholder={"Rechercher un Parent"}
    //     bg={"#fff"}
    //     h={30}
    //     radius={20}
    //     mb={20}
    //     onChange={(e) => setQuery(e.target.value.toLowerCase())}
    //     value={query}
    //   />
    //   <div className="">
    //     {isLoading ? (
    //       <Loader type={true} />
    //     ) : data?.data.length > 0 ? (
    //       [...data?.data]
    //         .filter((niveau) => niveau?.name.toLowerCase().includes(query))
    //         .filter((niveau) =>
    //           niveau?.name
    //             .toLowerCase()
    //             .includes(params?.matiere.toLowerCase())
    //         )
    //         .sort((a, b) => a?.name.split(" ")[1] - b?.name.split(" ")[1])
    //         .map((item) => (
    //           <div
    //             className=""
    //             style={{
    //               backgroundColor: "#fff",
    //               width: "100%",
    //               padding: ".7rem",
    //               borderRadius: 10,
    //               marginBottom: 10,
    //               cursor: "pointer",
    //             }}
    //             key={item?.id}
    //             onClick={() => setSelect(item?.id)}
    //           >
    //             <HacFlex gap={10} wrap="nowrap">
    //               <div
    //                 className=""
    //                 style={{
    //                   backgroundColor: "rgba(0,0,0,0.1)",
    //                   minWidth: 50,
    //                   minHeight: 50,
    //                   display: "grid",
    //                   placeItems: "center",
    //                   borderRadius: "50%",
    //                 }}
    //               >
    //                 <img
    //                   src={`../../../../../icons/${
    //                     params?.matiere.toLowerCase() === "lecture"
    //                       ? "book"
    //                       : "math"
    //                   }.png`}
    //                   alt=""
    //                   width={25}
    //                 />
    //               </div>
    //               <HacFlex className="" position={"apart"} w={"100%"}>
    //                 <HacText weight={600} size={15} color={"#003880"}>
    //                   {item?.name}
    //                 </HacText>
    //                 {/* <HacText mt={7}>Telephone: {item?.phone}</HacText> */}
    //                 {/* <HacText>Status: A jour</HacText> */}
    //                 <div
    //                   className=""
    //                   style={{
    //                     width: 20,
    //                     height: 20,
    //                     borderRadius: "50%",
    //                     backgroundColor:
    //                       select === item?.id
    //                         ? "rgba(0,0,250,0.7)"
    //                         : "rgba(0,0,0,0.1)",
    //                     border: "2px solid gray",
    //                   }}
    //                 ></div>
    //               </HacFlex>
    //             </HacFlex>
    //           </div>
    //         ))
    //     ) : (
    //       <Empty />
    //     )}
    //     {data?.data.length > 0 && (
    //       <div className="" style={{ height: 50 }}></div>
    //     )}
    //   </div>
    // </div>
    //   {data?.data.length > 0 && (
    // <div
    //   className=""
    //   style={{
    //     position: "fixed",
    //     bottom: 0,
    //     left: 0,
    //     width: "100%",
    //     padding: "1rem",
    //   }}
    // >
    //   <button
    //     style={{
    //       width: "100%",
    //       height: 40,
    //       borderRadius: 15,
    //       outline: "none",
    //       backgroundColor: bgColorPrimary,
    //       color: "#fff",
    //       fontWeight: 600,
    //       opacity: select ? 1 : 0.7,
    //     }}
    //     onClick={() => select && navigation(`${select}`)}
    //   >
    //     Continuer
    //   </button>
    // </div>
    //   )}
    // </SafeArea>
  );
}
