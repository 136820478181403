// import Deconnexion from "components/Deconnexion";
import Empty from "components/Empty";
import HacFlex from "components/HacFlex";
import HacInputText from "components/HacInputText";
import HacText from "components/HacText";
import Loader from "components/Loader";
import { Template } from "layouts/Template";
import HeaderDiagnostique from "pages/testDePositionnement/HeaderDiagnostique";
import React, { useState } from "react";
// import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useGetParentsQuery } from "redux/features/parentApi";

function ParentList() {
  const navigation = useNavigate();
  const { data, isLoading } = useGetParentsQuery();

  const [query, setQuery] = useState("");

  return (
    <Template
      mainStyle={{ backgroundColor: "rgba(0,0,0,0.1)" }}
      topBar={<HeaderDiagnostique title={"Liste des parents"} />}
    >
      <div
        style={{
          padding: ".5rem",
          paddingTop: 20,
        }}
      >
        <div style={{ position: "sticky", top: 10 }}>
          <HacInputText
            placeholder={"Rechercher un Parent"}
            bg={"#fff"}
            h={20}
            radius={10}
            mb={20}
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
            value={query}
          />
        </div>

        {isLoading ? (
          <Loader type={true} />
        ) : data?.data.length > 0 ? (
          [...data?.data]
            .filter((parent) => parent?.fullName.toLowerCase().includes(query))
            .map((item) => (
              <div
                className=""
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  padding: ".7rem",
                  borderRadius: 10,
                  marginBottom: 10,
                }}
                key={item?.id}
                onClick={() =>
                  navigation("eleves/" + item?.id, { state: item?.fullName })
                }
              >
                <HacFlex gap={10}>
                  <div
                    className=""
                    style={{
                      backgroundColor: "rgba(0,0,0,0.1)",
                      width: 50,
                      height: 50,
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "50%",
                    }}
                  >
                    <img src="../../icons/faceBlack.png" alt="" width={25} />
                  </div>
                  <div className="">
                    <HacText weight={600} size={15} color={"#003880"}>
                      {item?.fullName}
                    </HacText>
                    <HacText size={13} mt={7}>
                      Telephone: {item?.phone}
                    </HacText>
                    {/* <HacText>Status: A jour</HacText> */}
                  </div>
                </HacFlex>
              </div>
            ))
        ) : (
          <Empty />
        )}

        <div className="" style={{ height: 10 }}></div>
      </div>
    </Template>
  );
}

export default ParentList;
