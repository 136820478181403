import { ApiMananger } from "./apiMananger";

const matiereApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getMatieres: build.query({
      query: () => `/matieres/`,
      providesTags: ["Matieres"],
      provides: { data: true },
      cacheTime: 24 * 60 * 60 * 1000, // Durée en millisecondes (1 jour)
      staleTime: 60 * 60 * 1000, // Durée en millisecondes (1 heure)
    }),
  }),
});

export const { useGetMatieresQuery } = matiereApi;
