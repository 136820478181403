import HacText from "components/HacText";
import Loader from "components/Loader";
import { bgColorPrimary } from "constants";
import useGetUserInfo from "hooks/useGetUserInfo";
import { Template } from "layouts/Template";
import HeaderDiagnostique from "pages/testDePositionnement/HeaderDiagnostique";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetClassesQuery } from "redux/features/classeApi";
import { useGetCoachQuery } from "redux/features/coachApi";
// import { useGetMatieresQuery } from "redux/features/matiereApi";
import { addSchoolId } from "redux/features/userSlice";
import { addClasseId } from "redux/features/userSlice";

export default function SelectSchool() {
  const navigation = useNavigate();
  // const [check, setCheck] = useState(null);

  const { userId, schoolId, classeId } = useGetUserInfo();

  const [form, setForm] = useState({
    ecole: schoolId ?? "",
    classe: classeId ?? "",
  });

  const dispatch = useDispatch();

  const { data: coach } = useGetCoachQuery(userId, {
    refetchOnFocus: true,
  });
  const { data: classesData, isLoading: classeLoading } = useGetClassesQuery();
  // const { data: matiereData, isLoading: matiereLoading } =
  //   useGetMatieresQuery();

  const handleChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  // console.log({ matiereData });

  return (
    <Template
      mainStyle={{ backgroundColor: "rgba(0,0,0,0.1)" }}
      topBar={<HeaderDiagnostique title={"Diagnostique"} />}
      bottomBarStyle={{ backgroundColor: "rgba(0,0,0,0.1)" }}
      bottomBar={
        <div
          className=""
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <button
            style={{
              width: "90%",
              height: 35,
              borderRadius: 15,
              outline: "none",
              backgroundColor: bgColorPrimary,
              color: "#fff",
              fontWeight: 600,
              colorAdjust: 1,
              // opacity: form?.classe && form?.ecole ? 1 : 0.7,
            }}
            disabled={
              !form?.classe && !form?.ecole && coach?.schools.length > 0
            }
            onClick={() => {
              // if(form.ecole === "0000") navigation("options")
              // form.ecole === "0000" ||
              // form?.classe && form?.ecole && coach?.schools.length > 0
              //   else
              navigation("options");
              // navigation("eleves")
            }}
          >
            {form.ecole === "0000" ? "Continuer sans ecole" : "Continuer"}
          </button>
        </div>
      }
    >
      <div
        style={{
          padding: ".5rem",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          gap: 20,
          marginTop: 10,
        }}
      >
        <HacText style={{ position: "sticky" }} size={15} ta={"center"}>
          Chosissez l'école et la classe
        </HacText>
        <div className="ecole">
          <HacText style={{ marginBottom: 10 }} size={13}>
            Ecoles
          </HacText>
          <select
            name="classe"
            id="classe"
            style={{
              width: "100%",
              height: 35,
              borderRadius: 10,
              padding: "0 1rem",
              fontSize: 17,
            }}
            // defaultValue={schoolId}
            onChange={(e) => {
              handleChange("ecole", e.target.value);
              dispatch(addSchoolId(parseInt(e.target.value)));
            }}
            value={form?.ecole ?? ""}
          >
            <option value="">Choisir une ecole</option>
            <option value="0000">Sans ecole</option>
            {coach?.schools.map((school) => (
              <option
                value={school.id}
                key={school.id}
                // onClick={() => alert(school.name)}
              >
                {school.name}
              </option>
            ))}
          </select>
        </div>

        {/* {form?.ecole === "0000" ? null : ( */}
        <div className="classes" style={{}}>
          <HacText style={{ marginBottom: 10 }} size={13}>
            Classes
          </HacText>

          <div
            className="input"
            style={{
              columnCount: 2,
            }}
          >
            {classeLoading ? (
              <Loader type={true} />
            ) : (
              classesData?.map((classe, index) => (
                <div
                  className="classe"
                  key={index}
                  style={{
                    display: "flex",
                    gap: 10,
                    width: "100%",
                    alignItems: "center",
                    backgroundColor: "rgba(0,0,200, 0.08)",
                    padding: ".7rem",
                    borderRadius: 10,
                    marginBottom: 15,
                  }}
                  onClick={() => {
                    dispatch(addClasseId(classe?.id));
                    handleChange("classe", classe?.id);
                  }}
                >
                  <input
                    type="radio"
                    name="classe"
                    id={classe?.name}
                    style={{ width: 20, backgroundColor: "red" }}
                    onChange={(e) => handleChange("classe", e.target.value)}
                    checked={classe?.id === classeId}
                  />
                  <label
                    htmlFor={classe?.name}
                    style={{ fontWeight: 500, fontSize: 15 }}
                  >
                    {classe?.name}
                  </label>
                </div>
              ))
            )}
          </div>
        </div>
        {/* )} */}

        {/* <div className="classes" style={{}}>
          <HacText style={{ marginBottom: 10 }} size={13}>
            Matieres
          </HacText>

          <div
            className="input"
            style={{
              columnCount: 2,
            }}
          >
            {matiereLoading ? (
              <Loader type={true} />
            ) : (
              matiereData?.data?.map((matiere, index) => (
                <div
                  style={{
                    padding: "1.3rem .8rem",
                    borderRadius: 10,
                    backgroundColor: "rgba(0, 56, 128, 0.15)",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginLeft: 0,
                    position: "relative",
                    marginBottom: 10,
                  }}
                  key={index}
                  onClick={() => setCheck("lecture/" + matiere?.id)}
                >
                  <div
                    className=""
                    style={{
                      width: 15,
                      height: 15,
                      borderRadius: "50%",
                      border: "1px solid #003880",
                      backgroundColor:
                        check === "lecture/" + matiere?.id
                          ? "green"
                          : "rgba(0, 56, 128, 0.50)",
                      position: "absolute",
                      top: 10,
                      left: 10,
                    }}
                  ></div>
                  <div
                    style={{
                      borderRadius: 10,
                      backgroundColor: "#fff",
                      width: 40,
                      height: 40,
                      display: "grid",
                      placeItems: "center",
                      alignSelf: "center",
                    }}
                  >
                    <img src="../../../icons/book.png" alt="" width={20} />
                  </div>

                  <HacText mt={10} color={"#003880"} ta={"center"}>
                    {matiere?.name}
                  </HacText>
                </div>
              ))
            )}
          </div>
        </div> */}
        {/* <p style={{ height: 40 }}></p> */}
      </div>
    </Template>
  );
}
