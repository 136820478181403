import React, { useEffect, useState } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { persistor, store } from "redux/app/Store";
import { PersistGate } from "redux-persist/integration/react";
import { AuthProvider } from "context/AuthContext";
import Root from "Root";
import { BrowserRouter } from "react-router-dom";
import { DrawerProvider } from "context/DrawerContext";
import ModalInstall from "components/ModalInstall";
import { Toaster } from "react-hot-toast";

function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [opened, setOpened] = useState(false);

  const handleInstall = () => {
    setOpened(false);

    // Show the prompt
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        console.log({ choiceResult });
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    async function actionInstall(e) {
      e.preventDefault();
      setDeferredPrompt(e);
      setOpened(true);
    }

    window.addEventListener("beforeinstallprompt", actionInstall);
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <AuthProvider>
            <DrawerProvider>
              <div
                className="App"
                style={{
                  boxShadow: "1px 0 5px gray",
                  maxWidth: 500,
                  overflow: "hidden",
                  margin: "0 auto",
                }}
              >
                <Root />
                <ToastContainer
                  position="bottom-center"
                  autoClose={3000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
                <Toaster />
                <ModalInstall
                  opened={opened}
                  onInstall={handleInstall}
                  onClosed={() => setOpened(false)}
                />
              </div>
            </DrawerProvider>
          </AuthProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
