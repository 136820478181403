import useGetUserInfo from "hooks/useGetUserInfo";
import React, { useState } from "react";
import { useGetElevesBySchoolByClasseQuery } from "redux/features/eleveApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setElevePositionnement } from "redux/features/userSlice";
import HacFlex from "components/HacFlex";
import HeaderDiagnostique from "./HeaderDiagnostique";
import HacText from "components/HacText";
import HacInputText from "components/HacInputText";
import Empty from "components/Empty";
import Loader from "components/Loader";
import { useGetNiveauxQuery } from "redux/features/niveauApi";
import { Template } from "layouts/Template";

function ListeEleveByCoachClasseSchool() {
  const { schoolId, classeId, eleve } = useGetUserInfo();

  const navigation = useNavigate();
  const dispatch = useDispatch();

  const [query, setQuery] = useState("");

  const { data: eleveBySchoolByClasse, isLoading } =
    useGetElevesBySchoolByClasseQuery({
      schoolId,
      classeId,
    });

  const { data } = useGetNiveauxQuery();

  return (
    <Template
      mainStyle={{ backgroundColor: "rgba(0,0,0,0.1)" }}
      topBar={
        <HeaderDiagnostique
          title={"Liste des élèves: " + eleve?.school?.name}
        />
      }
    >
      <div className="">
        <div
          style={{
            padding: ".5rem",
          }}
        >
          <div className="" style={{ position: "sticky", top: 10 }}>
            <HacInputText
              placeholder={"Rechercher un élève"}
              bg={"#fff"}
              h={20}
              radius={10}
              mb={20}
              onChange={(e) => setQuery(e.target.value.toLowerCase())}
              value={query}
            />
          </div>

          {isLoading ? (
            <Loader type={true} />
          ) : eleveBySchoolByClasse?.data.length > 0 ? (
            [...eleveBySchoolByClasse?.data]
              .filter(
                (eleve) =>
                  eleve?.firstName.toLowerCase().includes(query) ||
                  eleve?.lastName.toLowerCase().includes(query)
              )
              .map((item) => (
                <div
                  className=""
                  style={{
                    backgroundColor: "#fff",
                    width: "100%",
                    padding: ".7rem",
                    borderRadius: 10,
                    marginBottom: 10,
                  }}
                  key={item?.id}
                  onClick={() => {
                    dispatch(setElevePositionnement(item));
                    navigation("eleve");
                  }}
                >
                  <HacFlex gap={15}>
                    <div
                      className=""
                      style={{
                        backgroundColor: "rgba(0,0,0,0.1)",
                        width: 50,
                        height: 50,
                        display: "grid",
                        placeItems: "center",
                        borderRadius: "50%",
                      }}
                    >
                      <img src="../../icons/faceBlack.png" alt="" width={25} />
                    </div>
                    <div className="">
                      <HacText weight={700} size={15} color={"#003880"}>
                        {item?.firstName} {item?.lastName}
                      </HacText>
                      <HacText mt={3} size={12}>
                        Ecole: {item?.school?.name}
                      </HacText>
                      <HacText mt={3} size={12}>
                        Classe: {item?.classe?.name}
                      </HacText>
                      <HacFlex gap={10} mt={10}>
                        {item?.niveaux.map((niveau, index) => (
                          <div
                            key={niveau}
                            style={
                              {
                                // backgroundColor: "rgba(0,0,0,0.07)",
                                // padding: "3px 10px",
                                // borderRadius: 20,
                                // border: "1px solid rgba(0,0,0,0.1)",
                              }
                            }
                          >
                            <HacText size={10} color={"gray"}>
                              {
                                data?.data.find((niv) => niv.id === niveau)
                                  ?.name
                              }{" "}
                              {index + 1 === item?.niveaux.length ? null : "/"}
                            </HacText>
                          </div>
                        ))}
                      </HacFlex>
                    </div>
                  </HacFlex>
                </div>
              ))
          ) : (
            <Empty />
          )}
          <p style={{ height: 10 }}></p>
        </div>
      </div>
    </Template>
  );
}

export default ListeEleveByCoachClasseSchool;
