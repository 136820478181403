import HistoriqueDetail from "components/historique/detail";
import { Template } from "layouts/Template";
import HeaderDiagnostique from "pages/testDePositionnement/HeaderDiagnostique";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetDiagnostiqueTentavivesByElevesGroupeByTententativeDetailsQuery } from "redux/features/diagnostiqueApi";

function DetailHistoriquePage() {
  const params = useParams();

  const { data, isLoading } =
    useGetDiagnostiqueTentavivesByElevesGroupeByTententativeDetailsQuery(
      parseInt(params?.tentativeId)
    );
  return (
    <Template
      topBar={<HeaderDiagnostique title={"Detail de la tentative"} />}
      mainStyle={{ backgroundColor: "rgba(0,0,0,0.1)", padding: ".5rem" }}
    >
      <HistoriqueDetail data={data ? data?.data : []} isLoading={isLoading} />
    </Template>
  );
}

export default DetailHistoriquePage;
