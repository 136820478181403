import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetMatieresQuery } from "redux/features/matiereApi";
import { Template } from "layouts/Template";
import HeaderDiagnostique from "./HeaderDiagnostique";
import HacText from "components/HacText";
import HacFlex from "components/HacFlex";
import { useGetNiveauxQuery } from "redux/features/niveauApi";
import {
  // useGetDiagnostiqueTentativeByEleveQuery,
  useGetDiagnostiqueTentavivesByElevesQuery,
} from "redux/features/diagnostiqueApi";
// import Loader from "components/Loader";
import Tentative from "components/tentative";
// import useGetUserInfo from "hooks/useGetUserInfo";

function TestDePositionnementElevePageDesign() {
  const navigation = useNavigate();
  // const location = useLocation();
  // const [searchParams, setSearchParams] = useSearchParams();

  // const { diagnostiqueType } = useGetUserInfo();

  // const [check, setCheck] = useState(null);

  const eleveInfo = useSelector(
    (state) => state?.dataPersisted.user?.elevePositionnement
  );

  const { data: matiereData } = useGetMatieresQuery();
  const { data } = useGetNiveauxQuery();

  // const { data: diagnostiqueData, isLoading: diagnosticLoading } =
  //   useGetDiagnostiqueTentativeByEleveQuery(eleveInfo?.id);

  const { data: tentativeData, isLoading: diagnosticLoadings } =
    useGetDiagnostiqueTentavivesByElevesQuery(eleveInfo?.id);

  // console.log({ eleveInfo });

  return (
    <Template
      mainStyle={{
        backgroundColor: "rgba(0,0,0,0.1)",
        padding: ".5rem",
        display: "flex",
        flexDirection: "column",
      }}
      topBar={<HeaderDiagnostique title={"Faire le diagnostic"} />}
      bottomBarStyle={{ backgroundColor: "rgba(0,0,0,0.1)" }}
      // bottomBar={
      //   <div
      //     className=""
      //     style={{
      //       width: "100%",
      //       // padding: "1rem",
      //       height: "100%",
      //       display: "grid",
      //       placeItems: "center",
      //     }}
      //   >
      //     <button
      //       style={{
      //         width: "90%",
      //         height: 35,
      //         borderRadius: 15,
      //         outline: "none",
      //         backgroundColor: bgColorPrimary,
      //         color: "#fff",
      //         fontWeight: 600,
      //         colorAdjust: 1,
      //         // opacity: form?.classe && form?.ecole ? 1 : 0.7,
      //       }}
      //       onClick={() => {
      //         if (check) {
      //           navigation(`/listdiagnostique`, { state: { matiere: check } });
      //         } else {
      //           alert("Selectionner d'abord une matiere");
      //         }
      //       }}
      //     >
      //       Continuer
      //     </button>
      //   </div>
      // }
    >
      <div className="">
        <HacText>Sélectionner une matière pour évaluer</HacText>

        <HacFlex gap={10} my={10} wrap="nowrap">
          <div
            className=""
            style={{
              backgroundColor: "rgba(0,0,0,0.1)",
              minWidth: 90,
              minHeight: 90,
              display: "grid",
              placeItems: "center",
              borderRadius: 10,
            }}
          >
            <img src="../../../icons/faceBlue.png" alt="" width={35} />
          </div>
          <div className="">
            <HacText weight={700} size={18} color={"#003880"}>
              {eleveInfo?.firstName} {eleveInfo?.lastName}
            </HacText>
            <HacText mt={6} size={12}>
              Ecole: {eleveInfo?.school?.name}
            </HacText>
            <HacText mt={3} size={12}>
              Classe: {eleveInfo?.classe?.name}
            </HacText>
            <HacFlex gap={5} mt={10}>
              {eleveInfo?.niveaux.map((niveau, index) => (
                <div
                  key={niveau}
                  style={
                    {
                      // backgroundColor: "rgba(0,0,0,0.07)",
                      // padding: "3px 10px",
                      // borderRadius: 20,
                      // border: "1px solid rgba(0,0,0,0.1)",
                    }
                  }
                >
                  <HacText size={10} color={"gray"}>
                    {data?.data.find((niv) => niv.id === niveau)?.name}{" "}
                    {index + 1 === eleveInfo?.niveaux.length ? null : "/"}
                  </HacText>
                </div>
              ))}
            </HacFlex>
          </div>
        </HacFlex>

        <HacFlex gap={20} wrap="nowrap">
          {matiereData?.data.map((matiere) => (
            <div
              style={{
                padding: "1.3rem .8rem",
                borderRadius: 10,
                backgroundColor: "rgba(0, 56, 128, 0.15)",
                width: "50%",
                marginLeft: 0,
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
              key={matiere?.id}
              // onClick={() => setCheck(matiere?.name)}
              onClick={() =>
                navigation(`/listdiagnostique`, {
                  state: { matiere: matiere?.name, matiereId: matiere?.id },
                })
              }
            >
              <div
                style={{
                  // padding: "1rem",
                  borderRadius: 10,
                  backgroundColor: "#fff",
                  width: 40,
                  height: 40,
                  display: "grid",
                  placeItems: "center",
                  alignSelf: "center",
                }}
              >
                <img
                  src={`../../../icons/${
                    matiere?.name.toLowerCase() === "lecture" ? "book" : "math"
                  }.png`}
                  alt=""
                  width={20}
                />
              </div>

              <HacText mt={10} color={"#003880"} ta={"center"}>
                {matiere?.name}
              </HacText>
            </div>
          ))}
          {/* <div
          style={{
            padding: "1.3rem .8rem",
            borderRadius: 10,
            backgroundColor: "rgba(0, 56, 128, 0.15)",
            display: "flex",
            flexDirection: "column",
            width: "50%",
            marginLeft: 0,
            position: "relative",
          }}
          onClick={() => setCheck("lecture/" + matiereData?.data[0]?.id)}
        >
          <div
            className=""
            style={{
              width: 15,
              height: 15,
              borderRadius: "50%",
              border: "1px solid #003880",
              backgroundColor:
                check === "lecture/" + matiereData?.data[0]?.id
                  ? "green"
                  : "rgba(0, 56, 128, 0.50)",
              position: "absolute",
              top: 10,
              left: 10,
            }}
          ></div>
          <div
            style={{
              borderRadius: 10,
              backgroundColor: "#fff",
              width: 40,
              height: 40,
              display: "grid",
              placeItems: "center",
              alignSelf: "center",
            }}
          >
            <img src="../../../icons/book.png" alt="" width={20} />
          </div>

          <HacText mt={10} color={"#003880"} ta={"center"}>
            {matiereData?.data[0]?.name}
          </HacText>
        </div> */}
        </HacFlex>
      </div>

      <div
        className=""
        style={{
          width: "100%",
          height: 1,
          backgroundColor: "rgba(0,0,0,0.1)",
          margin: "10px 0",
        }}
      ></div>

      {/* <div
        className=""
        style={{
          borderRadius: 10,
          padding: ".5rem 0",
          maxHeight: "100%",
          overflow: "hidden",
          backgroundColor: "#fff",
        }}
      >
        <div
          className=""
          style={{
            backgroundColor: "#fff",
            borderRadius: 10,
            overflowY: "auto",
            maxHeight: "100%",

            padding: "1rem",
          }}
        >
          <HacText>Historiques</HacText>
          <div
            className=""
            style={{
              width: "100%",
              height: 1,
              backgroundColor: "rgba(0,0,0,0.1)",
              margin: "10px 0",
            }}
          ></div>

          {diagnosticLoading ? (
            <Loader type={true} />
          ) : diagnostiqueData?.data.length > 0 ? (
            diagnostiqueData?.data.map((item, index) => (
              <div
                key={item?.id}
                className=""
                style={{ marginBottom: 15, cursor: "pointer" }}
                onClick={() =>
                  navigation("/ecoles/options/historiques/" + item.id, {
                    state: {
                      eleveId: eleveInfo?.id,
                      seuil: "70",
                      diagnosticId: item?.id,
                    },
                  })
                }
              >
                <HacFlex position={"apart"}>
                  <div className="" style={{ width: "100%" }}>
                    <HacFlex position={"apart"}>
                      <HacText size={12} weight={600}>
                        {item?.diagnostic?.title ?? "vide item"}
                      </HacText>
                      <HacText weight={700} size={11}>
                        {item?.date_diagnostic.split("-").reverse().join("-")}
                      </HacText>
                    </HacFlex>

                    <HacText weight={700} size={11} mt={10}>
                      {item?.status}
                    </HacText>
                  </div>
                </HacFlex>

                {index + 1 === diagnostiqueData?.data.length ? null : (
                  <div
                    className=""
                    style={{
                      width: "100%",
                      height: 1,
                      backgroundColor: "rgba(0,0,0,0.1)",
                      margin: "15px 0",
                    }}
                  ></div>
                )}
              </div>
            ))
          ) : (
            <HacText>Pas de note</HacText>
          )}
        </div>
      </div> */}

      {/* Liste des tentatives */}
      <Tentative
        eleve={eleveInfo}
        isLoading={diagnosticLoadings}
        data={tentativeData ? tentativeData?.diagnostics : []}
        onClick={(history) =>
          navigation("/ecoles/options/historiques/" + history.id, {
            state: {
              eleveId: eleveInfo?.id,
              seuil: "70",
              name: history?.diagnostic.split(" ").at(-1),
            },
          })
        }
        // onClick={() =>
        //   navigation("/ecoles/options/historiques/" + item.id, {
        //     state: {
        //       eleveId: eleveInfo?.id,
        //       seuil: "70",
        //       diagnosticId: item?.id,
        //     },
        //   })
        // }
      />
    </Template>
  );
}

export default TestDePositionnementElevePageDesign;
