import Deconnexion from "components/Deconnexion";
import HacFlex from "components/HacFlex";
import HacText from "components/HacText";
import SafeArea from "components/SafeArea";
import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function ParentPage() {
  const navigation = useNavigate();

  return (
    <SafeArea bg={"rgba(0,0,0,0.1)"}>
      <header
        style={{
          backgroundColor: "#fff",
          height: 50,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "sticky",
          top: 0,
          zIndex: 5,
          padding: "0 1rem",
        }}
      >
        <HacFlex w={"100%"} position={"apart"}>
          <FaArrowLeft size={20} onClick={() => navigation(-1)} />
          <h3 style={{}}>Diagnostique</h3>
          <Deconnexion />
        </HacFlex>
      </header>

      <div
        style={{
          height: "100%",
          padding: ".5rem",
          paddingTop: 20,
        }}
      >
        {[1, 2, 3].map((item) => (
          <div
            className=""
            style={{
              backgroundColor: "#fff",
              width: "100%",
              padding: "1rem",
              borderRadius: 10,
              marginBottom: 10,
            }}
            key={item}
          >
            <HacFlex gap={20}>
              <div
                className=""
                style={{
                  backgroundColor: "rgba(0,0,0,0.1)",
                  width: 60,
                  height: 60,
                  display: "grid",
                  placeItems: "center",
                  borderRadius: "50%",
                }}
              >
                <img src="../../icons/faceBlack.png" alt="" width={30} />
              </div>
              <div className="">
                <HacText weight={700} size={20} color={"#003880"}>
                  Abou Sow
                </HacText>
                <HacText mt={3}>Classe: CP</HacText>
                <HacText>Status: A jour</HacText>
              </div>
            </HacFlex>
          </div>
        ))}
      </div>
    </SafeArea>
  );
}

export default ParentPage;
