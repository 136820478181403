import { IconPhone } from "@tabler/icons-react";
import HacFlex from "components/HacFlex";
import HacInputText from "components/HacInputText";
import HacText from "components/HacText";
import Loader from "components/Loader";
import { Template } from "layouts/Template";
import HeaderDiagnostique from "pages/testDePositionnement/HeaderDiagnostique";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetAllCoachQuery } from "redux/features/coachApi";
import { addUserId } from "redux/features/userSlice";

function CoachPage() {
  const navigation = useNavigate();

  const dispatch = useDispatch();

  const { data, isLoading } = useGetAllCoachQuery();

  const [query, setQuery] = useState("");

  return (
    <Template
      mainStyle={{ backgroundColor: "rgba(0,0,0,0.1)" }}
      topBar={<HeaderDiagnostique title={"Liste des coachs"} />}
    >
      <div
        style={{
          padding: ".5rem",
        }}
      >
        <div style={{ position: "sticky", top: 10 }}>
          <HacInputText
            placeholder={"Rechercher un coach"}
            bg={"#fff"}
            h={20}
            radius={10}
            mb={20}
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
            value={query}
          />
        </div>

        {isLoading ? (
          <Loader type={true} />
        ) : (
          data?.data.map((item) => (
            <div
              className=""
              style={{
                backgroundColor: "#fff",
                width: "100%",
                padding: ".7rem",
                borderRadius: 10,
                marginBottom: 10,
              }}
              key={item?.id}
              onClick={() => {
                dispatch(addUserId(item?.id));
                return navigation("/ecoles");
              }}
            >
              <HacFlex gap={15}>
                <div
                  className=""
                  style={{
                    backgroundColor: "rgba(0,0,0,0.1)",
                    width: 50,
                    height: 50,
                    display: "grid",
                    placeItems: "center",
                    borderRadius: "50%",
                  }}
                >
                  <img src="../../icons/faceBlack.png" alt="" width={25} />
                </div>
                <div className="">
                  <HacText weight={700} size={15} color={"#003880"}>
                    {item?.fullName}
                  </HacText>
                  <HacFlex mt={4} gap={5}>
                    <IconPhone size={15} />
                    <HacText size={13}>{item?.phone}</HacText>
                  </HacFlex>
                  {/* <HacText>Status: A jour</HacText> */}
                </div>
              </HacFlex>
            </div>
          ))
        )}
      </div>
    </Template>
  );
}

export default CoachPage;
