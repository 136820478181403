import Deconnexion from "components/Deconnexion";
import HacFlex from "components/HacFlex";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "tabler-icons-react";

function HeaderDiagnostique({ title, paht }) {
  const navigation = useNavigate();

  return (
    <HacFlex
      bg={"#fff"}
      w={"100%"}
      position={"apart"}
      style={{
        position: "sticky",
        top: 0,
        zIndex: 5,
        padding: ".4rem .5rem",
        borderBottom: "1px solid rgba(0,0,0,0.1)",
      }}
    >
      <ArrowLeft size={20} onClick={() => navigation(paht ?? -1)} />
      <h4 style={{}}>{title}</h4>
      <Deconnexion />
    </HacFlex>
  );
}

export default HeaderDiagnostique;
