import React from "react";
// import { RotatingLines } from "react-loader-spinner";
import HacButton from "./HacButton";
import HacText from "./HacText";
import HacInputText from "./HacInputText";
// import styles from "assets/styles.module.css";

// const Form2 = ({ children, onSubmit }) => {
//   const [values, setValues] = React.useState({});

//   const handleChange = (name, value) => {
//     setValues((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     onSubmit(values);
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       {React.Children.map(children, (child) => {
//         if (child.type === Form.Group) {
//           return React.cloneElement(child, {
//             onChange: handleChange,
//             values,
//           });
//         }
//         return child;
//       })}
//     </form>
//   );
// };

const Form = ({ children, onSubmit, validations, ...props }) => {
  const [values, setValues] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [error, setError] = React.useState({});

  const handleChange = (name, value) => {
    setValues((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: validate(name, value) }));
    setError({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log({ errors: Object.values(errors) });
    if (!Object.values(errors).find((value) => value !== null)) {
      onSubmit(values);
    } else {
      setError({ ...errors });
      // setTimeout(() => {
      //   setError({});
      // }, 3000);
    }
  };

  const validate = (name, value) => {
    const validationFunc = validations && validations[name];
    if (validationFunc) {
      return validationFunc(value) || null;
    }
  };

  return (
    <form onSubmit={handleSubmit} {...props}>
      {React.Children.map(children, (child) => {
        if (child) {
          if (child.type === Form.Group) {
            return React.cloneElement(child, {
              onChange: handleChange,
              value: values[child.props.name] || "",
              error: error[child.props.name] || child.props.error,
            });
          }
          return child;
        }
      })}
    </form>
  );
};

// Form.Group2 = ({ label, name, children, onChange, values }) => {
//   return (
//     <div style={{ display: "flex", flexDirection: "column", marginBottom: 20 }}>
//       <label style={{ fontSize: 16, marginBottom: 5 }}>{label}</label>
//       {React.Children.map(children, (child) => {
//         return React.cloneElement(child, {
//           name,
//           value: values[name] || "",
//           onChange: (e) => onChange(name, e.target.value),
//         });
//       })}
//     </div>
//   );
// };

Form.Group = ({ label, name, children, onChange, value, error, style }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", marginBottom: 20 }}>
      <label style={{ fontSize: 16, marginBottom: 5 }}>{label}</label>
      {React.Children.map(children, (child) => {
        if (child.type === Form.Input) {
          return React.cloneElement(child, {
            name,
            value,
            onChange: (e) => onChange(name, e.target.value),
            style: {
              border: `1px solid ${error ? "red" : "rgba(0,0,0,0.2)"}`,
              color: error ? "red" : "#000",
              ...style,
            },
          });
        }
      })}
      {error && (
        <p
          style={{ color: "red", fontSize: 12, fontWeight: 400, marginTop: 5 }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

Form.Input = ({ name, value = "", radius, onChange, style, ...props }) => {
  return (
    // <input
    //   name={name}
    //   value={value}
    //   onChange={onChange}
    //   {...props}
    //   style={{
    //     padding: 10,
    //     backgroundColor: "rgba(0,0,0,0)",
    //     fontSize: 16,
    //     borderRadius: radius ?? 5,
    //     outline: "none",
    //     ...style,
    //   }}
    // />
    <HacInputText
      h={20}
      onChange={onChange}
      name={name}
      value={value}
      radius={radius}
      {...props}
      w={"90%"}
    />
  );
};

Form.Textarea = ({ name, value, onChange, ...props }) => {
  return <textarea name={name} value={value} onChange={onChange} {...props} />;
};

// const Button = ({
//   title,
//   radius,
//   fullidth,
//   loading,
//   disabled,
//   style,
//   ...props
// }) => {
//   return (
//     <button
//       {...props}
//       style={{
//         // padding: 10,
//         borderRadius: radius ?? 5,
//         height: 40,
//         cursor: "pointer",
//         width: fullidth && "100%",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         ...style,
//       }}
//       disabled={loading || disabled}
//     >
//       {loading && (
//         <RotatingLines
//           strokeColor="#fff"
//           strokeWidth="5"
//           animationDuration="0.75"
//           width="25"
//           visible={true}
//         />
//       )}
//       <div style={{ marginLeft: 3 }}>{title}</div>
//     </button>
//   );
// };

Form.Button = ({ type, ...props }) => (
  // <Button type={type ?? "submit"} {...props} />
  <HacButton
    type={type ?? "submit"}
    w={"93%"}
    h={20}
    radius={10}
    style={{ flex: 1 }}
    {...props}
  >
    <HacText>Se connecter</HacText>
  </HacButton>
);

// const Group = ({ direction, space, children, ...props }) => (
//   <div
//     style={{
//       display: "flex",
//       flexDirection: direction ?? "row",
//       gap: space ?? 10,
//     }}
//   >
//     {children}
//   </div>
// );

export default Form;
