import HacText from "components/HacText";
import { bgColorPrimary } from "constants";
import React from "react";
import { FaFacebookMessenger } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function FinishDiagnostique() {
  const navigation = useNavigate();

  const eleve = useSelector((state) => state?.dataPersisted.diagnostic.elevePositionnement);

  return (
    <div
      style={{
        backgroundColor: bgColorPrimary,
        height: "100dvh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: ".6rem",
      }}
    >
      <div className="logo" style={{ marginTop: 30 }}>
        {/* <img
          style={{ width: 70 }}
          src="../../../../../icons/icon1.png"
          alt=""
        /> */}
      </div>
      <div
        className="image"
        style={{
          marginTop: -40,
          color: "#fff",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <img style={{ width: "100%" }} src=".diagnostique.png" alt="" /> */}
        <h1 style={{ textAlign: "center" }}>Bravo Diagnostique terminé !</h1>
      </div>
      <div
        className="info"
        style={{
          height: "auto",
          backgroundColor: "#fff",
          width: "100%",
          borderRadius: 10,
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          //   alignItems: "center",
          gap: 20,
          fontWeight: 600,
          lineHeight: 1.5,
        }}
      >
        <HacText size={16}>Resultat</HacText>

        <div
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            padding: ".7rem",
            backgroundColor: "rgba(0,0,200,0.1)",
            borderRadius: 10,
            gap: 20,
          }}
        >
          <div
            className=""
            style={{
              backgroundColor: bgColorPrimary,
              borderRadius: "50%",
              display: "grid",
              placeItems: "center",
              height: 40,
              width: 40,
              color: "#fff",
            }}
          >
            <FaFacebookMessenger size={20} />
          </div>
          <h3 style={{ color: bgColorPrimary }}>2 Exercices</h3>
        </div>

        <div
          className=""
          style={{
            marginTop: 20,
            marginBottom: 20,
            display: "flex",
            alignItems: "center",
            gap: 20,
          }}
        >
          <div
            className=""
            style={{
              width: 60,
              height: 60,
              borderRadius: "50%",
              backgroundColor: "rgba(0,0,0,0.1)",
              display: "grid",
              placeItems: "center",
            }}
          >
            <img
              style={{ width: 30, height: 30 }}
              src="../../../../../../icons/faceBlue.png"
              alt=""
            />
          </div>
          <div className="">
            <HacText weight={600} size={16}>
              {eleve?.firstName} {eleve?.lastName}
            </HacText>
            <HacText size={12}>Classe: {eleve?.classe?.name}</HacText>
            <HacText size={12}>Ecole: {eleve?.school?.name}</HacText>
          </div>
        </div>

        <HacText size={18} style={{ textAlign: "center", color: "green" }}>
          Diagnostique Reussi
        </HacText>

        <button
          style={{
            width: "100%",
            height: 45,
            borderRadius: 15,
            outline: "none",
            backgroundColor: bgColorPrimary,
            color: "#fff",
            fontWeight: 600,
            marginTop: 30,
          }}
          onClick={() => navigation("/acceuil")}
        >
          Retour
        </button>
      </div>
    </div>
  );
}

export default FinishDiagnostique;
