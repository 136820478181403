import { ApiMananger } from "./apiMananger";

const niveauApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    getNiveaux: build.query({
      query: () => `/niveaux/`,
      providesTags: ["Niveaux"],
    }),
  }),
});

export const { useGetNiveauxQuery } = niveauApi;
