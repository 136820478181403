// import HacButton from "components/HacButton";
import HacFlex from "components/HacFlex";
import HacText from "components/HacText";
import useGetUserInfo from "hooks/useGetUserInfo";
import { Template } from "layouts/Template";
import HeaderDiagnostique from "pages/testDePositionnement/HeaderDiagnostique";
import React from "react";
import { useNavigate } from "react-router-dom";

function Options() {
  // const [check, setCheck] = useState("diagnostique");
  const { schoolId } = useGetUserInfo();

  const navigation = useNavigate();

  return (
    <Template
      topBar={<HeaderDiagnostique title={"Séléctionner un élément"} />}
      mainStyle={{ backgroundColor: "rgba(0,0,0,0.1)", padding: "1rem" }}
      bottomBarStyle={{ backgroundColor: "rgba(0,0,0,0.1)", padding: ".5rem" }}
    >
      <HacText ta={"center"} weight={700} size={18} mt={20}>
        Choisissez une option
      </HacText>
      <HacFlex gap={20} wrap="nowrap" mt={30}>
        <div
          style={{
            padding: "1.5rem .8rem",
            borderRadius: 10,
            backgroundColor: "#fff",
            width: "50%",
            marginLeft: 0,
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
          onClick={() =>
            navigation(schoolId === 0 ? "eleveWithoutSchool" : "diagnostique")
          }
        >
          <div
            style={{
              // padding: "1rem",
              borderRadius: 10,
              backgroundColor: "#fff",
              width: 60,
              height: 60,
              display: "grid",
              placeItems: "center",
              alignSelf: "center",
            }}
          >
            <img src="../../../icons/diagnostique.png" alt="" width={30} />
          </div>

          <HacText mt={10} color={"#003880"} weight={700} ta={"center"}>
            Diagnostiques
          </HacText>
        </div>
        <div
          style={{
            padding: "1.5rem .8rem",
            borderRadius: 10,
            backgroundColor: "#fff",
            width: "50%",
            marginLeft: 0,
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
          onClick={() => navigation("historiques")}
        >
          <div
            style={{
              // padding: "1rem",
              borderRadius: 10,
              backgroundColor: "#fff",
              width: 60,
              height: 60,
              display: "grid",
              placeItems: "center",
              alignSelf: "center",
            }}
          >
            <img src="../../../icons/historique.png" alt="" width={30} />
          </div>

          <HacText mt={10} color={"#003880"} weight={700} ta={"center"}>
            Historiques
          </HacText>
        </div>
      </HacFlex>
    </Template>
  );
}

export default Options;
